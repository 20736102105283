import React, { useState, useEffect } from "react";

import "../../App.css";
import Select, { components } from "react-select";
import {
  collection,
  addDoc,
  getDocs,
  Timestamp,
  doc,
  updateDoc,
  where,
  deleteDoc,
  query,
  getDoc,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "./../../firebaseConfig";
import TalentCalendar from "../TalentCalendar";
import closeButton from "../../images/close.svg";
import calendarIcon from "../../images/calendar.svg";
import selectIcon from "../../images/select.svg";

import { yearsToMonths } from "date-fns";
import DatePicker from "react-datepicker";

function ProposeTalentsBookingDetail() {
  const [skipEffect, setSkipEffect] = useState(false);
  const navigate = useNavigate();
  const [similarBookings, setSimilarBookings] = useState([]);
  const [search, setSearch] = useState(""); // New state for search term
  const [sortOrder, setSortOrder] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [talents, setTalents] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedTalentdet, setSelectedTalentdet] = useState(null);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [selectedTalentName, setSelectedTalentName] = useState(null);
  const [selectedTalentEmail, setSelectedTalentEmail] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [bookingDetails, setBookingDetails] = useState(null);
  const [selectedCostCenter, setSelecedCostcenter] = useState(null);
  const [selectedCostCentertwo, setSelecedCostcentertwo] = useState(null);
  const [editingbokkingTalentid, setEditingbookingTalentid] = useState("");
  const [editingbookingCompany, setEditingbookingcompany] = useState("");
  const [loading, setLoading] = useState(true);
  const [kpi, setKpi] = useState("KPI");
  const [set, setSet] = useState("SET");
  const [position, setPosition] = useState("");
  const [bookinglocation, setbookinglocation] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedFunction, setSelectedFunction] = useState();
  const location = useLocation();
  const bookingId = new URLSearchParams(location.search).get("bookingId");
  const [groupID, setGroupID] = useState(
    new URLSearchParams(location.search).get("group")
  );
  const [times, setTimes] = useState("");
  const [groupColor, setgroupColor] = useState("");
  const [selectedPosition, setselectedPosition] = React.useState(null);
  const [selectedbookingoption, setselectedbookingoptions] = useState();
  const [recipientId, setRecipientId] = useState("");
  const [message, setMessage] = useState("");
  const [scheduledTime, setScheduledTime] = useState(new Date());
  const [allbookingdata, setallbookingdata] = useState();
  const [choosedTalentAllData, setchoosedTalentAllData] = useState();
  const [locations, setLocations] = useState([]);
  const [selectLocations, setSelectedLocations] = useState([]);
  const [customDayRate, setCustomDayRate] = useState(null);
  const [customDayRateCheck, setCustomDayRateCheck] = useState(false);
  const isTimeIn30MinInterval = (time) => {
    return new Date(time).getMinutes() % 30 === 0;
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const scheduledAt = Timestamp.fromDate(new Date(scheduledTime));
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const scheduleReminder = async () => {
    const reminderDoc = {
      senderId: localStorage.getItem("userEmail"),
      recipientId: localStorage.getItem("userEmail"),
      message: "A reminder to check booking" + editingbookingCompany,
      scheduledAt: scheduledTime,
      createdAt: Timestamp.now(),
      url: "admin/detail?id=" + bookingId,
      status: "scheduled", // can be 'scheduled', 'sent', or 'cancelled'
    };
    const reminderDocTalent = {
      senderId: selectedTalentEmail,
      recipientId: selectedTalentEmail,
      message: "A reminder to check booking" + editingbookingCompany,
      scheduledAt: scheduledTime,
      createdAt: Timestamp.now(),
      url: "talent/my-bookings-page",
      status: "scheduled", // can be 'scheduled', 'sent', or 'cancelled'
    };
    const reminderDocPlaning = {
      senderId: "planning",
      recipientId: "planning",
      message: "A reminder to check booking" + editingbookingCompany,
      scheduledAt: scheduledTime,
      url: "cs/proposed",
      createdAt: Timestamp.now(),
      status: "scheduled", // can be 'scheduled', 'sent', or 'cancelled'
    };

    if (isChecked1) {
      await addDoc(
        collection(
          db,
          "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/scheduled_notifications/"
        ),
        reminderDocTalent
      );
    }
    if (isChecked2) {
      addDoc(
        collection(
          db,
          "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/scheduled_notifications/"
        ),
        reminderDocPlaning
      );
    }
    if (isChecked3) {
      await addDoc(
        collection(
          db,
          "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/scheduled_notifications/"
        ),
        reminderDoc
      );
    }
  };

  // Set the minTime and maxTime
  const minTime = new Date();
  minTime.setHours(8, 0, 0); // 8 AM
  const maxTime = new Date();
  maxTime.setHours(19, 0, 0); // 7 PM
  const handlePostionchange = (selectedPosition) => {
    setselectedPosition(selectedPosition);
  };

  const hadleSelectedBookingOption = (selectedOption) => {
    setselectedbookingoptions(selectedOption.value);
  };

  useEffect(() => {
    // If we should skip the effect, do nothing.
    fetchlocations();
    if (skipEffect) return;

    // Function to update Firebase.
    const updateFirebase = async () => {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, {
        isBeingProcessed: false,
      });
    };

    // Handler for beforeunload event.
    const onBeforeUnload = (event) => {
      event.preventDefault();
      updateFirebase();
      // For Chrome, set returnValue.
      event.returnValue = "test";
    };

    // Handler for navigation changes.
    const onNavigateAway = () => {
      updateFirebase();
    };

    // Add event listeners for unload and navigation events.
    window.addEventListener("beforeunload", onBeforeUnload);
    window.addEventListener("popstate", onNavigateAway);

    // Cleanup function to remove the event listeners.
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      window.removeEventListener("popstate", onNavigateAway);
    };
  }, [skipEffect, bookingId, db]);

  useEffect(() => {
    // Return cleanup function that will run when the component unmounts
    // or when the location changes.
    const updateFirebase = async () => {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, {
        isBeingProcessed: false,
      });
    };
    return () => {
      updateFirebase();
    };
  }, [location]);

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      border: "none",
    }),
    control: (base) => ({
      ...base,
      width: "400px",
      borderColor: "black",
      borderWidth: "1px",
      boxShadow: "none",
      margin: "5px",
      borderRadius: "0px",
      color: "black", // Your color
      fontWeight: "700",
      height: "50px",
      textTransform: "uppercase",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black", // Your color
      fontWeight: "700",
    }),
    input: (provided) => ({
      ...provided,
      color: "black", // Your color
      fontWeight: "700",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "black" : "inherit",
      "&:hover": { backgroundColor: state.isSelected ? "black" : "#f7f7f7" },
    }),
  };
  const constcenter1 = [
    {
      id: "714",
      label: "Post Outsourcing",
      accountNumber: "70175",
      value: "714",
    },
    {
      id: "715",
      label: "Virtual Production-Operator",
      accountNumber: "70155",
      value: "715",
    },
    { id: "716", label: "Videograf", accountNumber: "70145", value: "716" },
    { id: "717", label: "Foto Still", accountNumber: "70110", value: "717" },
    { id: "718", label: "Styling Still", accountNumber: "70111", value: "718" },
    { id: "719", label: "Foto Life", accountNumber: "70120", value: "719" },
    { id: "720", label: "Styling Life", accountNumber: "70121", value: "720" },
    {
      id: "721",
      label: "Foto-Assistenz Life",
      accountNumber: "70122",
      value: "721",
    },
    { id: "722", label: "Models", accountNumber: "70124", value: "722" },
    {
      id: "723",
      label: "Hair & Make up",
      accountNumber: "70130",
      value: "723",
    },
    { id: "724", label: "Art Direction", accountNumber: "70160", value: "724" },
    {
      id: "725",
      label: "Post Production",
      accountNumber: "70170",
      value: "725",
    },
    { id: "726", label: "Text", accountNumber: "70180", value: "726" },
    {
      id: "727",
      label: "Sonstiges Produktion",
      accountNumber: "70190",
      value: "727",
    },
    {
      id: "728",
      label: "Verpflegung Shooting",
      accountNumber: "70193",
      value: "728",
    },
    { id: "729", label: "Producing", accountNumber: "70230", value: "729" },
    { id: "730", label: "Modelbooking", accountNumber: "70240", value: "730" },
    { id: "731", label: "Art Buying", accountNumber: "70250", value: "731" },
    {
      id: "732",
      label: "Logistik/Warenaufbereitung",
      accountNumber: "70260",
      value: "732",
    },
    {
      id: "733",
      label: "Technical Support",
      accountNumber: "70270",
      value: "733",
    },
    { id: "734", label: "Setbau", accountNumber: "70280", value: "734" },
    {
      id: "735",
      label: "Account Management",
      accountNumber: "70320",
      value: "735",
    },
    {
      id: "736",
      label: "Sales/Marketing",
      accountNumber: "70440",
      value: "736",
    },
    { id: "737", label: "IT/Technik", accountNumber: "70450", value: "737" },
    {
      id: "739",
      label: "Still Operator",
      accountNumber: "70115",
      value: "739",
    },
  ];
  const constcenter2 = [
    {
      id: "738",
      label: "Kunden-übergreifend / Sonstiges",
      accountNumber: "709005",
      value: "738",
    },
    {
      id: "740",
      label: "S.Oliver Produktfoto",
      accountNumber: "700021",
      value: "740",
    },
    {
      id: "741",
      label: "Comma Produktfoto",
      accountNumber: "700031",
      value: "741",
    },
    {
      id: "742",
      label: "Liebeskind Produktfoto",
      accountNumber: "700041",
      value: "742",
    },
    {
      id: "743",
      label: "Görtz Produktfoto",
      accountNumber: "700051",
      value: "743",
    },
    {
      id: "744",
      label: "PUMA Produktfoto",
      accountNumber: "700061",
      value: "744",
    },
    {
      id: "745",
      label: "PUMA Kampagne",
      accountNumber: "700062",
      value: "745",
    },
    {
      id: "746",
      label: "TomTailor Produktfoto",
      accountNumber: "700071",
      value: "746",
    },
    {
      id: "747",
      label: "TomTailor Kamagne",
      accountNumber: "700072",
      value: "747",
    },
    {
      id: "748",
      label: "BonPrix Produktfoto",
      accountNumber: "700091",
      value: "748",
    },
    {
      id: "749",
      label: "HFG Produktfoto",
      accountNumber: "700101",
      value: "749",
    },
    { id: "750", label: "HFG Kampagne", accountNumber: "700102", value: "750" },
    {
      id: "751",
      label: "Juvia Produktfoto",
      accountNumber: "700111",
      value: "751",
    },
    {
      id: "752",
      label: "HugoBoss Produktfoto",
      accountNumber: "700121",
      value: "752",
    },
    {
      id: "753",
      label: "HugoBoss Kampagne",
      accountNumber: "700122",
      value: "753",
    },
    {
      id: "754",
      label: "Bogner Produktfoto",
      accountNumber: "700601",
      value: "754",
    },
    {
      id: "755",
      label: "Bogner Kampagne",
      accountNumber: "700602",
      value: "755",
    },
    {
      id: "756",
      label: "Heine Produktfoto",
      accountNumber: "700621",
      value: "756",
    },
    {
      id: "757",
      label: "WittWeiden Produktfoto",
      accountNumber: "705071",
      value: "757",
    },
    {
      id: "758",
      label: "Zalando EPS Produktfoto",
      accountNumber: "705141",
      value: "758",
    },
    {
      id: "759",
      label: "Zalando EPS Kampagne",
      accountNumber: "705142",
      value: "759",
    },
    {
      id: "760",
      label: "ToryBurch Produktfoto",
      accountNumber: "705171",
      value: "760",
    },
    {
      id: "761",
      label: "Falke Produktfoto",
      accountNumber: "705181",
      value: "761",
    },
    {
      id: "762",
      label: "Zalando RPDP Produktfoto",
      accountNumber: "705221",
      value: "762",
    },
    {
      id: "763",
      label: "Sonstige Kleinkunden Produktfoto",
      accountNumber: "707991",
      value: "763",
    },
    {
      id: "766",
      label: "PUMA Virtual Production",
      accountNumber: "700066",
      value: "766",
    },
    {
      id: "767",
      label: "C&A Produktfoto",
      accountNumber: "703421",
      value: "767",
    },
    { id: "768", label: "C&A Kampagne", accountNumber: "703422", value: "768" },
    {
      id: "769",
      label: "C&A Virtual Production",
      accountNumber: "703429",
      value: "769",
    },
    {
      id: "773",
      label: "Ziener Produktfoto",
      accountNumber: "705051",
      value: "773",
    },
    {
      id: "772",
      label: "Zalando EPDP Produktfoto HH",
      accountNumber: "705191",
      value: "772",
    },
    {
      id: "778",
      label: "Tommy Hilfiger Produktfoto",
      accountNumber: "740411",
      value: "778",
    },
  ];

  const booking = [
    { label: "Request", value: "requested" },
    { label: "Option", value: "optioned" },
  ];
  const handleSelectCostCenter = (selectedOption) => {
    setSelecedCostcenter(selectedOption);
  };
  const handleSelectCostCentertwo = (selectedOption) => {
    setSelecedCostcentertwo(selectedOption);
  };
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: "16px", margin: "0" }}>
            <svg
              version="1.1"
              id="Isolationsmodus"
              x="0px"
              y="0px"
              viewBox="0 0 322.9 257.78"
              shapeRendering="geometricPrecision"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z"
              />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };
  const DropdownIndicator2 = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: "16px", margin: "0" }}>
            <svg
              version="1.1"
              id="Isolationsmodus"
              x="0px"
              y="0px"
              viewBox="0 0 322.9 257.78"
              shapeRendering="geometricPrecision"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z"
              />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };
  const fetchTalentDetails = async (talentId) => {
    const talentRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
      talentId
    );
    const talentSnap = await getDoc(talentRef);
    return talentSnap.exists() ? talentSnap.data() : null;
  };
  useEffect(() => {
    // Fetch companies and talents
    const fetchCompaniesAndTalents = async () => {
      const companySnap = await getDocs(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company")
      );
      const talentSnap = await getDocs(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data")
      );
      const companyData = companySnap.docs.map((doc) => ({
        value: doc.id,
        label: doc.data().name, // assumes the company document has a 'name' field
      }));
      const talentData = talentSnap.docs.map((doc) => ({
        value: doc.id,
        Name: doc.data().Name, // assumes the talent document has a 'name' field
        E_Mail: doc.data().E_Mail,
        Funktion: doc.data().Funktion,
        Tagessatz: doc.data().Tagessatz,
        Bereich: doc.data().Bereich,
        goodPerformanceCompany: doc.data().goodPerformanceCompany,
        badPerformanceCompany: doc.data().badPerformanceCompany,
        alldata: doc.data(),
        relatedTalent: doc.data().relatedTalent,
        tryOutCompany: doc.data().tryOutCompany,
        locations: doc.data().locations,
      }));
      const fetchTalentDetails = async (talentId) => {
        const talentRef = doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          talentId
        );
        const talentSnap = await getDoc(talentRef);
        return talentSnap.exists() ? talentSnap.data() : null;
      };
      setCompanies(companyData);
      setTalents(talentData);
      // If bookingId is present, fetch the booking details
      // If bookingId is present, fetch the booking details
      if (bookingId) {
        const bookingSnap = await getDocs(
          collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings")
        );
        const bookingData = bookingSnap.docs.find(
          (doc) => doc.id === bookingId
        );

        if (bookingData) {
          setBookingDetails(bookingData.data());
          let companyID = bookingData.data().companyID; // Split the string at '/' and get the last element
          let company = companyData.find(
            (company) => company.value === companyID
          ); // Use the extracted id to find the company
          setEditingbookingcompany(bookingData.data().client.label);
          setallbookingdata(bookingData.data());
          setgroupColor(bookingData.data().groupColor);
          setSelectedCompany(company);
          setSet(bookingData.data().set);
          setKpi(bookingData.data().kpi);
          setPosition(bookingData.data().position.label);
          setStatus(bookingData.data().status);
          setbookinglocation(bookingData.data().location)
          setSelectedFunction(bookingData.data().position.label);
          setGroupID(bookingData.data().group);
        
          setStartDate(
            bookingData
              .data()
              .start_date.toDate()
              .toLocaleDateString()
              .split("T")[0]
          );
          setEndDate(
            bookingData
              .data()
              .end_date.toDate()
              .toLocaleDateString()
              .split("T")[0]
          );
          setTimes(
            bookingData.data().startTime + "-" + bookingData.data().endTime
          );
          setSelectedLocations (bookingData.data().location? bookingData.data().location :''
                          )
        }
        // Updated to fetch documents with either "optionSolved" or "talentsOnHold" status
        const statusOptions = ["optionSolved", "talentOnHold"];
        const allOptionSlovedAndTalentsOnHoldBookingsSnap = await getDocs(
          query(
            collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
            where("status", "in", statusOptions)
          )
        );

        const fetchSimilarBookingsWithTalentDetails = async () => {
          const similarBookings =
            allOptionSlovedAndTalentsOnHoldBookingsSnap.docs
              .map((doc) => ({
                id: doc.id, // Include the document ID
                ...doc.data(),
              }))
              .filter(
                (booking) =>
                  booking.position.label ===
                    bookingData.data().position.label &&
                  booking.start_date
                    .toDate()
                    .toLocaleDateString()
                    .split("T")[0] ===
                    bookingData
                      .data()
                      .start_date.toDate()
                      .toLocaleDateString()
                      .split("T")[0]
              );

          const bookingsWithTalentDetails = await Promise.all(
            similarBookings.map(async (booking) => {
              const talentDetails = await fetchTalentDetails(booking.talentId);
              return {
                ...booking,
                talentName: talentDetails ? talentDetails.Name : "Unknown",
                talentEmail: talentDetails ? talentDetails.E_Mail : "Unknown",
                talentRate: talentDetails ? talentDetails.Tagessatz : "Unknown",
              };
            })
          );

          console.log(bookingsWithTalentDetails);
          setSimilarBookings(bookingsWithTalentDetails);
        };

        fetchSimilarBookingsWithTalentDetails();
      }
      setLoading(false);
    };
    fetchCompaniesAndTalents();
    getBookingsWithGroupId(groupID);
  }, []);
  const handleTalentChange = (selectedOption) => {
    setSelectedTalent(selectedOption);
  };
  const createmail = async (
    TalentName,
    allbookingdata,
    choosedTalentAllData
  ) => {
    const msg = {
      to:
        "booking@set-cast.com, talent@mondula.com," +
        localStorage.getItem("userEmail"),
      message: {
        subject: "Talent has beed proposed",
        text: `You have beed proposed: ${JSON.stringify(allbookingdata)}`,
        html: `
        Dear  Team,
        <p>A new Invitation has been sent to ${TalentName} </p>
        <p>  StartDate:  ${startDate} </p>
        <p>  End Date:  ${allbookingdata.endDate} </p>
        <p>  Freelancer:  ${TalentName} </p>git p
        <p>  Company:  ${allbookingdata.clibet.label} </p>
        <p>  Position:  ${allbookingdata.position} </p>
        <p> 
        Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
        </p>`,
      },
    };
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"),
      msg
    );
  };
  const createmail2g = async (
    TalentName,
    allbookingdata,
    choosedTalentAllData,
    emailContent,
  ) => {


    const msg = {
      to:
        "talents@set-cast.com, booking@set-cast.com,  " +
        localStorage.getItem("userEmail") +
        "," +
        choosedTalentAllData.E_Mail,
      message: {
        subject: `1.OPTION REQUEST  ${allbookingdata.client.label} - ${TalentName} -  ${startDate} `,
        text: `You have beed proposed: ${JSON.stringify(allbookingdata)}`,
        html: `
            Dear  ${TalentName},
           
            <p>Hope your day is fine. May I have your first option for:</p>
    
         ${emailContent} 
      


           
            <p>SETCast contact person:  ${allbookingdata.proposedBy} </p>
            <p>  LEGENDE:</p>
            <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
            <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
            <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
            <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
           
   
            <p> 
            Accept or deny your bookings in <a href="https://app.set-cast.com/talent/my-invitations">https://app.set-cast.com/talent/my-invitations </a>
            </p>
            
            <p> 
            Accept or deny on behalf of tlaent(For SetCast) <a href="https://app.set-cast.com/admin/requested">https://app.set-cast.com/admin/requested </a>
            </p>`,
      },
    };
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"),
      msg
    );
  };
  const createmail2 = async (
    TalentName,
    allbookingdata,
    choosedTalentAllData
  ) => {
    const rate = customDayRate ?? choosedTalentAllData.Tagessatz;
    const msg = {
      to:
        "talents@set-cast.com, booking@set-cast.com,  " +
        localStorage.getItem("userEmail") +
        "," +
        choosedTalentAllData.E_Mail,
      message: {
        subject: `1.OPTION REQUEST  ${allbookingdata.client.label} - ${TalentName} -  ${startDate} `,
        text: `You have beed proposed: ${JSON.stringify(allbookingdata)}`,
        html: `
            Dear  ${TalentName},
           
            <p>Hope your day is fine. May I have your first option for:</p>
            <p> STATUS: 1ST OPTION REQUEST</p>
            <p>  FUNCTION:  ${allbookingdata.position.label} </p>
            <p>  CLIENT:  ${allbookingdata.client.label} </p>
            <p>  Set:  ${allbookingdata.set} </p>
            <p>  DATE/PERIOD:  ${startDate} </p>
            <p>  TIME:  ${allbookingdata.startTime} -${allbookingdata.endTime} </p>
            <p>  DAYRATE:  ${rate} </p>
            <p>  KPI:  ${allbookingdata.kpi} </p>
            <p>  LOCATION:  ${allbookingdata.studio} - ${allbookingdata.location} </p>



           
            <p>SETCast contact person:  ${allbookingdata.proposedBy} </p>
            <p>  LEGENDE:</p>
            <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
            <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
            <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
            <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
           
   
            <p> 
            Accept or deny your bookings in <a href="https://app.set-cast.com/talent/my-invitations">https://app.set-cast.com/talent/my-invitations </a>
            </p>
            
            <p> 
            Accept or deny on behalf of tlaent(For SetCast) <a href="https://app.set-cast.com/admin/requested">https://app.set-cast.com/admin/requested </a>
            </p>`,
      },
    };
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"),
      msg
    );
  };

  const createmail4 = async (

    similarBooking,
  
  ) => {
    const msg = {
      to:
        "talents@set-cast.com, booking@set-cast.com,  " +
        localStorage.getItem("userEmail") +
        "," +
        similarBooking.talentEmail,
      message: {
        subject: "MODIFICATION",
        text: `You have beed proposed: ${JSON.stringify(similarBooking)}`,
        html: `
            Dear,
           
            <p>there has been a client change for your 1st option. We have changed it as follows</p>
            <p> STATUS: 1ST OPTION </p>
            <p>  FUNCTION:  ${similarBooking.position.label} </p>
            <p>  CLIENT:  ${similarBooking.client.label} </p>
            <p>  Set:  ${similarBooking.set} </p>
          
            <p>  TIME:  ${similarBooking.startTime} -${similarBooking.endTime} </p>
         
            <p>  KPI:  ${similarBooking.kpi} </p>
            <p>  LOCATION:  ${similarBooking.studio} - ${similarBooking.location} </p>


            <p>SETCast contact person:  ${similarBooking.proposedBy} </p>
            <p>  LEGENDE:</p>
            <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
            <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
            <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
            <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
           
   
            <p> 
            Check all your bookings in <a href="https://app.set-cast.com/talent/my-invitations">https://app.set-cast.com/talent/my-invitations </a>
            </p>`,
      },
    };
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"),
      msg
    );
  };
  const createmail3 = async (
    TalentName,
    allbookingdata,
    choosedTalentAllData
  ) => {
    const rate = customDayRate ?? choosedTalentAllData.Tagessatz;

    const msg = {
      to:
        "talents@set-cast.com, " 
        +
        choosedTalentAllData.E_Mail,
      message: {
        subject: `1. OPTION - ${allbookingdata.client.label} -  ${TalentName} -  ${startDate}`,
        text: `You have beed proposed: ${JSON.stringify(allbookingdata)}`,
        html: `
            Dear  ${TalentName},
           
            <p>as we have just discussed on the phone, I have noted the 1st option on you for the following production:</p>
            <p> STATUS: 1ST OPTION </p>
            <p>  FUNCTION:  ${allbookingdata.position.label} </p>
            <p>  CLIENT:  ${allbookingdata.client.label} </p>
            <p>  Set:  ${allbookingdata.set} </p>
            <p>  DATE/PERIOD:  ${startDate} </p>
            <p>  TIME:  ${allbookingdata.startTime} -${allbookingdata.endTime} </p>
            <p>  DAYRATE:  ${rate} </p>
            <p>  KPI:  ${allbookingdata.kpi} </p>
            <p>  LOCATION:  ${allbookingdata.studio} - ${allbookingdata.location} </p>

           
            <p>SETCast contact person:  ${allbookingdata.proposedBy} </p>
            <p>  LEGENDE:</p>
            <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
            <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
            <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
            <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
           
   
   
            <p> 
            Check all your bookings in <a href="https://app.set-cast.com/talent/my-invitations">https://app.set-cast.com/talent/my-invitations </a>
            </p>`,
      },
    };
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"),
      msg
    );
  };

  const createmail3g = async (
    TalentName,
    allbookingdata,
    choosedTalentAllData,
    emailContent
  ) => {
    const msg = {
      to:
        "talents@set-cast.com,  " +
       
        choosedTalentAllData.E_Mail,
      message: {
        subject: `1. OPTION - ${allbookingdata.client.label} -  ${TalentName} -  ${startDate}`,
        text: `You have beed proposed: ${JSON.stringify(allbookingdata)}`,
        html: `
            Dear  ${TalentName},
           
            <p>as we have just discussed on the phone, I have noted the 1st option on you for the following production:</p> 
         ${emailContent}
   
         <p>SETCast contact person:  ${allbookingdata.proposedBy} </p>
         <p>  LEGENDE:</p>
         <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
         <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
         <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
         <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
        

            <p> 
            Check all your bookings in <a href="https://app.set-cast.com/talent/my-invitations">https://app.set-cast.com/talent/my-invitations </a>
            </p>`,
      },
    };
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"),
      msg
    );
  };
  const checkForBookingConflicts = async (
    startDate,
    endDate,
    talentId,
    excludeBookingId
  ) => {
    // Fetch all bookings for the selected talent
    const bookingSnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings")
    );
    let talentBookings = bookingSnap.docs.filter(
      (doc) => doc.data().talentId === `${talentId}`
    );
    if (excludeBookingId) {
      talentBookings = talentBookings.filter(
        (doc) => doc.id !== excludeBookingId
      );
    }
    // Convert new booking dates to Date objects
    const newBookingStartDate = new Date(startDate);
    const newBookingEndDate = new Date(endDate);
    // Check if new booking dates conflict with existing bookings
    return talentBookings.some((booking) => {
      const bookingStartDate = new Date(booking.data().start_date.toDate());
      const bookingEndDate = new Date(booking.data().end_date.toDate());
      // Check for overlaps and exact matches
      return (
        (bookingStartDate < newBookingEndDate &&
          bookingEndDate > newBookingStartDate) ||
        (bookingStartDate.getTime() === newBookingStartDate.getTime() &&
          bookingEndDate.getTime() === newBookingEndDate.getTime())
      );
    });
  };

  const [samegroupbooking, setSamegroupbooking] = useState([]);

  const getBookingsWithGroupId = async (groupId) => {
    if (!groupId) {
      // If groupId is null or empty, return an empty array or handle accordingly
      return [];
    }
  
    const bookingSnap = await getDocs(
      query(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
        where("group", "==", groupId)
      )
    );

    const bookings = bookingSnap.docs.map((doc) => ({
      id: doc.id,

      ...doc.data(),
    }));

    // Set the bookings directly to the state
    setSamegroupbooking(bookings);
  };

  const getallBookingsWithGroupId = async (groupId) => {
    if (!groupId) {
      // If groupId is null or empty, return an empty array or handle accordingly
      return [];
    }
  
    const bookingSnap = await getDocs(
      query(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
        where("group", "==", groupId)
      )
    );
    return bookingSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const updateBookingWithGroupId = async (
    groupId,
    newData,
    TalentName,
    choosedTalentAllData
  ) => {
    const bookings = await getallBookingsWithGroupId(groupId);
    for (const booking of bookings) {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        booking.id
      );

      await updateDoc(bookingRef, newData);
      setallbookingdata(bookingRef);
    //  createmail2(TalentName, allbookingdata, choosedTalentAllData);
    }
  };
  const fetchBookingsByGroup = async (groupId) => {
    const bookingsRef = collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings");
    const q = query(bookingsRef, where("group", "==", groupId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Correctly return the booking data with id
  };
  
  
  const createGroupEmailContent = async (bookings) => {
    let emailContent = " ";
    let datesContent = bookings.map((booking) =>
      booking.start_date.toDate().toLocaleDateString()
    ).join(" - ");
    
    emailContent += ` ${datesContent}\n`;
    return emailContent;
  };
  
  const createEmailContentForGroupBooking = (bookings, choosedTalentAllData) => {
    // Map over each booking to create a content string for each
    const rate = customDayRate ?? choosedTalentAllData.Tagessatz;

    const bookingContents = bookings.map(booking => {
      const startDateFormatted = booking.start_date.toDate().toLocaleDateString();
      const endDateFormatted = booking.end_date.toDate().toLocaleDateString();
      return `
        <p> STATUS: 1ST OPTION </p>
        <p> FUNCTION: ${booking.position.label} </p>
        <p> CLIENT: ${booking.client.label} </p>
        <p> Set: ${booking.set} </p>
        <p> DATES/PERIOD: ${startDateFormatted} 
        <p> TIME: ${booking.startTime} - ${booking.endTime} </p>

        <p> DAYRATE: ${rate} </p> 
        <p> KPI: ${booking.kpi} </p>
        <p> LOCATION: ${booking.studio} - ${booking.location} </p>
     
      `;
    });
  
    // Join all booking contents together to form the complete email content
    const completeEmailContent = bookingContents.join('<hr>'); // Use <hr> to separate bookings, or choose another separator
  console.log(completeEmailContent);
    return completeEmailContent;
  };
  
  const handleSubmit = async (e) => {
    setSkipEffect(true);
    e.preventDefault();
      // Check if the selections are made
  if (!selectedCostCenter || !selectedCostCentertwo) {
    alert('Please select both cost centers.');
    return;
  }
    // Check if it's a new booking or an update
    if (bookingId) {
      // Update existing booking
      try {
        const bookingRef = doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
          bookingId
        );
        const isConflict = await checkForBookingConflicts(
          startDate,
          endDate,
          editingbokkingTalentid,
          bookingId
        );
        if (isConflict) {
          alert("Error: Talent is already booked within the selected dates.");
        } else {
          await updateDoc(bookingRef, {
            talentId: `${selectedTalent}`,
            status: selectedbookingoption,
            costcenter1: selectedCostCenter,
            costcenter2: selectedCostCentertwo,
            proposedBy: localStorage.getItem("userEmail"),
            isBeingProcessed: false,
            statusUpdatedAt: Timestamp.now(),
            customRate: customDayRate,
          });

          const newdata = {
            talentId: `${selectedTalent}`,
            status: selectedbookingoption,
            costcenter1: selectedCostCenter,
            costcenter2: selectedCostCentertwo,
            proposedBy: localStorage.getItem("userEmail"),
            isBeingProcessed: false,
            statusUpdatedAt: Timestamp.now(),
            customRate: customDayRate,
          };
          const newValue = {
            company: editingbookingCompany,
            talentId: selectedTalent,
            startDate: startDate,
            endDate: endDate,
            position: position,
            talentName: selectedTalentName,
            costcenter1: selectedCostCenter,
            costcenter2: selectedCostCentertwo,
            statusUpdatedAt: Timestamp.now(),
            customRate: customDayRate,
          };
          // Pass the selected talent's email and the new booking value to your sendmail function
          // await createmail(selectedTalent.email, newValue);
          let TalentName = selectedTalentName;

          let promiseArray = [
            scheduleReminder(),
            //createmail(TalentName, allbookingdata, choosedTalentAllData),

            addDoc(
              collection(
                db,
                "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"
              ),
              {
                userId: selectedTalentEmail,
                message: `You have been Proposed for new job`,
                read: false,
                url: "talent/my-invitations",
                bookingId: bookingId,
                createdAt: Timestamp.now(),
              }
            ),
          ];

          if (selectedbookingoption == "optioned") {
            if (groupID && groupID !== null && groupID !== undefined) {
              const groupBookings = await fetchBookingsByGroup(groupID);
              const emailContent = await createEmailContentForGroupBooking(groupBookings,choosedTalentAllData );
              // Assuming createmail function exists and can send emails
              await createmail3g(TalentName,allbookingdata, choosedTalentAllData, emailContent); // Update parameters as necessary
            } else 
                 { createmail3(TalentName, allbookingdata, choosedTalentAllData);}
          } else if(selectedbookingoption == "requested") { 
            
            if  (groupID && groupID !== null && groupID !== undefined)  {
              const groupBookings = await fetchBookingsByGroup(groupID);
              const emailContent = await createEmailContentForGroupBooking(groupBookings,choosedTalentAllData );
              // Assuming createmail function exists and can send emails
              await createmail2g(TalentName, allbookingdata,choosedTalentAllData, emailContent); // Update parameters as necessary
            }  
             else  
                  {  createmail2(TalentName, allbookingdata, choosedTalentAllData);}
            }

          // Conditionally add updateBookingWithGroupId to the array
          if (groupID !== null && groupID !== undefined) {
            promiseArray.push(
              updateBookingWithGroupId(
                groupID,
                newdata,
                TalentName,
                choosedTalentAllData
              )
            );
          }

          await Promise.all(promiseArray);

          alert("Booking updated successfully.");
          window.location.href = "/admin/booking";
        }
      } catch (error) {
        console.error("Error updating booking: ", error);
      }
    }
    setSkipEffect(false);
  };
  const sortByName = () => {
    setTalents(
      [...talents].sort((a, b) => {
        return sortOrder
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      })
    );
    setSortOrder(!sortOrder); // Switch the sort order for the next click
  };
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };
  const handleFunctionChange = (e) => {
    setSelectedFunction(e.target.value);
  };
  useEffect(() => {
    if (startDate === "") {
      document.getElementById("start-date");
    }
    if (endDate === "") {
      document.getElementById("end-date");
    }
  }, [startDate, endDate]);
  const uniqueFunctions = [
    ...new Set(talents.map((talent) => talent.Funktion)),
  ];
  const functionOptions = uniqueFunctions.map((func) => ({
    value: func,
    label: func,
  }));
  const fetchlocations = async () => {
    const locationSnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations")
    );
    const locationData = locationSnap.docs.map((doc) => doc.data().short);
    setLocations(locationData);
  };
  const locationOptions = locations
    .map((locations) => ({
      value: locations,
      label: locations,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const mergeBooking = async (similarBooking) => {
    // Here, you'll need to decide how you want to merge the bookings.
    // For simplicity, let's say you're just updating the client of the current booking with the similar booking's client.

    const bookingRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
      bookingId
    );
    await updateDoc(bookingRef, {
      talentId: similarBooking.talentId,
      status: "optioned",
   
      costcenter1: selectedCostCenter,
      costcenter2: selectedCostCentertwo,
      proposedBy: localStorage.getItem("userEmail"),
      isBeingProcessed: false,
      statusUpdatedAt: Timestamp.now(),
      customRate: customDayRate,
      
    });

    // Optionally, delete the similar booking or update its status
    const similarBookingRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
      similarBooking.id
    );
    //  await deleteDoc(similarBookingRef); // or update its status or any other fields
    await updateDoc(similarBookingRef, {
      talentId: "null",
      status: "deleted",
      
    });
    createmail4(similarBooking)
    await addDoc(
      collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
      {
        userId: similarBooking.talentEmail,
        message: `Your job details are beed updated`,
        read: false,
        url: "talent/my-bookings-page",
        bookingId: bookingId,
        createdAt: Timestamp.now(),
      }
    );
    alert("Bookings talets choosen successfully!");
    window.location.href = "/admin/booking";
  };
  function isCompanyMatch(companies, targetCompany) {
    return (
      companies &&
      companies.some(
        (company) => company.label.toLowerCase() === targetCompany.toLowerCase()
      )
    );
  }
  function getPerformanceRanking(talent, editingBookingCompanyLower) {
    const isGoodMatch = isCompanyMatch(
      talent.goodPerformanceCompany,
      editingBookingCompanyLower
    );
    const isTryoutMatch = isCompanyMatch(
      talent.tryOutCompany,
      editingBookingCompanyLower
    );
    const isBadMatch = isCompanyMatch(
      talent.badPerformanceCompany,
      editingBookingCompanyLower
    );

    // Assign rankings based on priority
    if (isGoodMatch) return 1;
    if (isTryoutMatch) return 2;
    if (isBadMatch) return 4; // Bad performers get the lowest priority
    return 3; // No match for good or tryout
  }

  return (
    <section>
      <div className="content-wrapper">
        <h1>{bookingId ? "Propose Talent" : "Create Booking"}</h1>
      </div>
      <div
        style={{
          marginTop: "60px",
        }}
      >
        {bookingId ? (
          // If bookingId exists, render your 'editing' JSX here, example:
          <div>
            <div className="content-wrapper content-wrapper-talent-list">
              <div
                className="task-info"
                //style={{ backgroundColor: groupColor, color: groupColor ? "#fff" : "inherit" }}
              >
                <h2 style={{ marginBottom: "20px" }}>order details</h2>
                <div
                  style={{
                    display: "flex",
                    gap: "80px",
                    marginBottom: "40px",
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      Client
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {editingbookingCompany}
                    </b>
                  </div>
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      KPI
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {kpi}
                    </b>
                  </div>
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      Date
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {startDate}{" "}
                    </b>
                  </div>
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      Time
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {times}
                    </b>
                  </div>
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      Set
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {set}
                    </b>
                  </div>
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      Position
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {position}
                    </b>
                  </div>
                  <div>
                    <div style={{ fontWeight: "300", fontSize: "1rem" }}>
                      Location
                    </div>
                    <b style={{ marginBottom: "10px", display: "block" }}>
                      {bookinglocation}
                    </b>
                  </div>
                </div>
                <div>
                  {samegroupbooking.length > 0 ? (
                    <h3 style={{ marginBottom: "20px" }}>
                      Also proposing for these group orders
                    </h3>
                  ) : (
                    <></>
                  )}
                  <div className="group-tasks propose-list">
                    <ul>
                      {samegroupbooking.map((booking) => (
                        <li key={booking.id}>
                          <div
                            style={{
                              display: "flex",
                              gap: "80px",
                              paddingBottom: "10px",
                              fontSize: "1.2rem",
                              lineHeight: "1.5",
                            }}
                          >
                            <div style={{ width: "350px" }}>
                              {booking.client.label}
                            </div>
                            <div style={{ width: "200px" }}>
                              {booking.position.label}
                            </div>
                            <div style={{ width: "50px" }}>{booking.kpi}</div>
                            <div style={{ width: "100px" }}>
                              {
                                booking.start_date
                                  .toDate()
                                  .toLocaleDateString()
                                  .split("T")[0]
                              }
                            </div>
                            <div style={{ width: "150px" }}>
                              {booking.startTime}-{booking.endTime}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="notificaion-infos">
                <h2 style={{ marginTop: "30px" }}>Notifications</h2>
                <div
                  className="notification-checkboxes"
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      checked={isChecked1}
                      onChange={() => setIsChecked1(!isChecked1)}
                    />
                    <label htmlFor="checkbox-1"></label>
                  </div>
                  <div className="checkbox-label">Inform Talent</div>

                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox-2"
                      checked={isChecked2}
                      onChange={() => setIsChecked2(!isChecked2)}
                    />
                    <label htmlFor="checkbox-2"></label>
                  </div>
                  <div className="checkbox-label">Inform planing</div>
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox-3"
                      checked={isChecked3}
                      onChange={() => setIsChecked3(!isChecked3)}
                    />
                    <label htmlFor="checkbox-3"></label>
                  </div>
                  <div className="checkbox-label">Inform me</div>

                  <div className="datepicker-wrapper">
                    <DatePicker
                      selected={scheduledTime}
                      onChange={(date) => setScheduledTime(date)}
                      showTimeSelect
                      filterTime={isTimeIn30MinInterval}
                      filterDate={filterPassedTime}
                      minTime={minTime}
                      maxTime={maxTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </div>
                </div>
              </div>
              <div className="task-info">
                <div
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    maxWidth: "500px",
                  }}
                >
                  <h2 style={{ marginBottom: "20px" }}>select costcenters</h2>
                  <div
                    className="propose-selectors"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    
                    <div>
                      <Select
                        components={{ DropdownIndicator }}
                        options={constcenter1}
                        onChange={handleSelectCostCenter}
                        placeholder="CostCenter 1"
                        value={selectedCostCenter}
                        styles={customStyles}
                        required
                      />
                    </div>
                    <div>
                      <Select
                        components={{ DropdownIndicator2 }}
                        options={constcenter2}
                        onChange={handleSelectCostCentertwo}
                        placeholder="CostCenter 2"
                        value={selectedCostCentertwo}
                        styles={customStyles}
                        required
                      />
                    </div>
                  </div>
                  <div className="custom-checkbox"   style={{
                    marginTop: "00px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <input
                      type="checkbox"
                      id="checkbox-4"
                      checked={customDayRateCheck}
                      onChange={() => setCustomDayRateCheck(!customDayRateCheck)}
                    />
                    <label htmlFor="checkbox-4">      </label>
                    <div className="checkbox-label " style={{
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}>Special Rate</div>
                  </div>
            
                  {customDayRateCheck ? <>
                  <span className="talent-item-input-title">Custom Rate in euro - only numbers</span>
                    <input
                      className="inputtext"
                      value={customDayRate}
                      onChange={(e) => {
                        // Use a regular expression to allow only numbers
                        const value = e.target.value;
                        const regex = /^[0-9]*$/;
                        
                        if (regex.test(value)) {
                          setCustomDayRate(value);
                        }
                      }}
                    /></>
                    : <></>}
                </div>
              </div>
              {similarBookings.length === 0 ? (
                <div></div>
              ) : (
                <div className="task-info">
                  <h3 style={{ marginBottom: "10px" }}>
                    Similar reserved talent
                  </h3>
                  {similarBookings.map((booking) => (
                    <div
                      style={{ fontSize: "1.2rem", lineHeight: "1.5" }}
                      key={booking.id}
                    >
                      {booking.client.label} - {booking.position.label} -{" "}
                      {booking.talentName} - {booking.talentEmail} -{" "}
                      {
                        booking.start_date
                          .toDate()
                          .toLocaleDateString()
                          .split("T")[0]
                      }{" "}
                      {booking.startTime}- {booking.endTime}
                      <button
                        style={{ marginLeft: "20px" }}
                        className="button"
                        onClick={() => mergeBooking(booking)}
                      >
                        choose talent
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <div className="content-wrapper">
                <h2 style={{ marginTop: "40px", marginBottom: "20px" }}>
                  select talent
                </h2>
                <div
                  className="propose-selectors"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <input
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="inputtext search"
                  />
                  <Select
                    value={locationOptions.find(
                      (option) => option.value === selectLocations
                    )}
                    onChange={(option) =>
                      setSelectedLocations(option ? option.value : "")
                    }
                    options={locationOptions}
                    isClearable
                    placeholder="Select location"
                    styles={customStyles}
                  />

                  <Select
                    value={functionOptions.find(
                      (option) => option.value === selectedFunction
                    )}
                    onChange={(option) =>
                      setSelectedFunction(option ? option.value : "")
                    }
                    options={functionOptions}
                    isClearable
                    placeholder="Select Function"
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>
            {selectedTalent === null ? (
              <div className="content-wrapper content-wrapper-talent-list">
                <div style={{ maxWidth: "100%", marginTop: "40px" }}>
                  <div
                    className="propose-item-header"
                    style={{ maxWidth: "100%" }}
                  >
                    <div style={{ flexBasis: "300px" }}>name</div>
                    <div style={{ flexBasis: "200px" }}>function</div>
                    <div style={{ flexBasis: "200px" }}>type</div>
                    <div style={{ flexBasis: "200px" }}>rate</div>
                    <div style={{ flexBasis: "300px" }}>good Performer</div>
                    <div style={{ flexBasis: "300px" }}>Try out</div>
                    <div style={{ flexBasis: "300px" }}>bad Performer</div>

                    <div style={{ flexBasis: "80px" }}></div>
                  </div>
                  <div className="propose-list">
                    {talents
                      .filter((talent) => {
                        const searchLowerCase = search?.toLowerCase();

                        return (
                          (set
                            ? talent.Bereich.toLowerCase() === set.toLowerCase()
                            : true) &&
                          (selectedFunction
                            ? talent.Funktion === selectedFunction
                            : true) &&
                          (selectLocations
                            ? talent.locations &&
                              Array.isArray(talent.locations)
                              ? talent.locations.some(
                                  (location) =>
                                    location.value === selectLocations
                                )
                              : false
                            : true) &&
                          (String(talent.Name)
                            ?.toLowerCase()
                            .includes(searchLowerCase) ||
                            String(talent.Funktion)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.E_Mail)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.Gage)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.Notiz_Setcast)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.Tagessatz)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.Telefon)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.Verifizierung)
                              ?.toLowerCase()
                              .includes(searchLowerCase) ||
                            String(talent.Bereich)
                              ?.toLowerCase()
                              .includes(searchLowerCase))
                        );
                      })
                      .sort((a, b) => {
                        const editingBookingCompanyLower =
                          editingbookingCompany.toLowerCase();

                        const aRanking = getPerformanceRanking(
                          a,
                          editingBookingCompanyLower
                        );
                        const bRanking = getPerformanceRanking(
                          b,
                          editingBookingCompanyLower
                        );

                        return aRanking - bRanking; // Sort by ranking
                      })

                      .map((talent) => (
                        <div>
                          <div key={talent.value}>
                            <div className="propose-item">
                              <div style={{ flexBasis: "300px" }}>
                                {talent.Name}
                              </div>
                              <div style={{ flexBasis: "200px" }}>
                                {talent.Funktion}
                              </div>
                              <div style={{ flexBasis: "200px" }}>
                                {talent.Bereich}
                              </div>
                              <div style={{ flexBasis: "200px" }}>
                                {talent.Tagessatz}
                              </div>
                              <div
                                style={{ flexBasis: "300px", color: "green" }}
                              >
                                {talent.goodPerformanceCompany &&
                                Array.isArray(talent.goodPerformanceCompany)
                                  ? talent.goodPerformanceCompany
                                      .map((company) => company.label)
                                      .join(", ")
                                  : ""}
                              </div>

                              <div style={{ flexBasis: "300px" }}>
                                {talent.tryOutCompany &&
                                Array.isArray(talent.tryOutCompany)
                                  ? talent.tryOutCompany
                                      .map((company) => company.label)
                                      .join(", ")
                                  : ""}
                              </div>
                              <div style={{ flexBasis: "300px", color: "red" }}>
                                {talent.badPerformanceCompany &&
                                Array.isArray(talent.badPerformanceCompany)
                                  ? talent.badPerformanceCompany
                                      .map((company) => company.label)
                                      .join(", ")
                                  : ""}
                              </div>

                              <div style={{ flexBasis: "80px" }}>
                                <span style={{ marginRight: "20px" }}>
                                  {selectedTalentdet === talent.value ? (
                                    <button
                                      className="edit-button"
                                      onClick={() => setSelectedTalentdet(null)}
                                    >
                                      <img
                                        src={closeButton}
                                        alt="Close"
                                        className="edit-container"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="edit-button"
                                      onClick={() => {
                                        setSelectedTalentdet(talent.value);
                                      }}
                                    >
                                      <img
                                        src={calendarIcon}
                                        alt="Calendar"
                                        className="edit-container"
                                      />
                                    </button>
                                  )}
                                </span>
                                <button
                                  className="edit-button"
                                  onClick={() => {
                                    setSelectedTalent(talent.value);
                                    setSelectedTalentName(talent.Name);
                                    setSelectedTalentEmail(talent.E_Mail);
                                    setchoosedTalentAllData(talent.alldata);
                                    console.log(
                                      choosedTalentAllData + "alltalentdata"
                                    );
                                  }}
                                >
                                  <img
                                    src={selectIcon}
                                    alt="Select"
                                    className="edit-container"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          {selectedTalentdet === talent.value && (
                            <div
                              className="calendar"
                              style={{
                                marginLeft: "20px",
                                marginRight: "20px",
                              }}
                            >
                              <TalentCalendar
                                talentId={talent.value}
                                relatedTalents={talent.relatedTalent}
                              />
                              <TalentCalendar
                                talentId={talent.value}
                                initialDate={startDate}
                                relatedTalents={talent.relatedTalent}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="content-wrapper">
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "2rem",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "300",
                        fontSize: "1rem",
                        marginBottom: "5px",
                      }}
                    >
                      Selected Talent
                    </div>
                    {selectedTalentName}
                  </div>
                  <Select
                    components={{ DropdownIndicator2 }}
                    options={booking}
                    onChange={hadleSelectedBookingOption}
                    placeholder="status"
                  //  value={booking.option}
                    styles={customStyles}
                  />
                </div>
          
                <div className="content-wrapper">
                  <div className="button-wrapper">
                 
                    <button className="big-button" onClick={handleSubmit}>
                      {(selectedbookingoption == "optioned") ? "Propose" :"Request nehmen"}
                    </button>{" "}
                    <button
                      className="big-button"
                      onClick={() => {
                        setSelectedTalent(null);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div>Nothing to show</div>
          </>
        )}
      </div>
    </section>
  );
}
export default ProposeTalentsBookingDetail;
