// BookingModal.js
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { collection, addDoc, getDocs, Timestamp } from "firebase/firestore";
import { db } from './../../firebaseConfig';
import moment from 'moment';


const BookingModal = ({ isOpen, onClose, selectedDate, selectedTalentId, selectedFunktion, selectedTalentName, selectedTalentSet, selectedTalentEmail, talentRate }) => {
  const [companies, setCompanies] = useState([]);
  const [talents, setTalents] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const formattedDate = moment(selectedDate, "DD.MM.YY").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [status, setStatus] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [kpi, setKpi] = useState('');
  const [set, setSet] = useState('');
  const [segment, setSegment] = useState('');
  const [range, setRange] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [sinfo, setSinfo] = useState('');
  const [bookingoption, setbookingoption] = useState();
  const [client, setClient] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState({label: selectedFunktion, value: selectedFunktion});
  const [selectedCostCenter, setSelecedCostcenter] = useState(null);
  const [selectedCostCentertwo, setSelecedCostcentertwo] = useState(null);
  const [hideinfos, sethideinfos]= useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const fetchlocations = async () => {
    const locationSnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations"));  
    const locationData = locationSnap.docs.map(doc => doc.data().short); 
    setLocations(locationData);
  };
  const locationOptions = locations.map(locations => ({
    value: locations,
    label: locations
  })).sort((a, b) => a.label.localeCompare(b.label));
  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      border: 'none',
    }),
    control: (base) => ({
      ...base,
      width: '500px',
      borderColor: 'black',
      borderWidth: '1px',
      boxShadow: 'none',
      marginTop: '5px', marginBottom: '5px',
      borderRadius: '0px',
      color: 'black',
      fontWeight: "700",
      height: '20px',
      textTransform: 'uppercase',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
      fontWeight: "700"
    }),
    input: (provided) => ({
      ...provided,
      color: 'black',
      fontWeight: "700"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'black' : 'inherit',
      '&:hover': { backgroundColor: state.isSelected ? 'black' : '#f7f7f7' }
    }),
  };


  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: '16px', margin: '0' }}>
            <svg version="1.1" id="Isolationsmodus" x="0px" y="0px" viewBox="0 0 322.9 257.78" shapeRendering="geometricPrecision">
              <path xmlns="http://www.w3.org/2000/svg" d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z" />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };

  const constcenter1 = [
    {
      id: "714",
      label: "Post Outsourcing",
      accountNumber: "70175",
      value: "714",
    },
    {
      id: "715",
      label: "Virtual Production-Operator",
      accountNumber: "70155",
      value: "715",
    },
    { id: "716", label: "Videograf", accountNumber: "70145", value: "716" },
    { id: "717", label: "Foto Still", accountNumber: "70110", value: "717" },
    { id: "718", label: "Styling Still", accountNumber: "70111", value: "718" },
    { id: "719", label: "Foto Life", accountNumber: "70120", value: "719" },
    { id: "720", label: "Styling Life", accountNumber: "70121", value: "720" },
    {
      id: "721",
      label: "Foto-Assistenz Life",
      accountNumber: "70122",
      value: "721",
    },
    { id: "722", label: "Models", accountNumber: "70124", value: "722" },
    {
      id: "723",
      label: "Hair & Make up",
      accountNumber: "70130",
      value: "723",
    },
    { id: "724", label: "Art Direction", accountNumber: "70160", value: "724" },
    {
      id: "725",
      label: "Post Production",
      accountNumber: "70170",
      value: "725",
    },
    { id: "726", label: "Text", accountNumber: "70180", value: "726" },
    {
      id: "727",
      label: "Sonstiges Produktion",
      accountNumber: "70190",
      value: "727",
    },
    {
      id: "728",
      label: "Verpflegung Shooting",
      accountNumber: "70193",
      value: "728",
    },
    { id: "729", label: "Producing", accountNumber: "70230", value: "729" },
    { id: "730", label: "Modelbooking", accountNumber: "70240", value: "730" },
    { id: "731", label: "Art Buying", accountNumber: "70250", value: "731" },
    {
      id: "732",
      label: "Logistik/Warenaufbereitung",
      accountNumber: "70260",
      value: "732",
    },
    {
      id: "733",
      label: "Technical Support",
      accountNumber: "70270",
      value: "733",
    },
    { id: "734", label: "Setbau", accountNumber: "70280", value: "734" },
    {
      id: "735",
      label: "Account Management",
      accountNumber: "70320",
      value: "735",
    },
    {
      id: "736",
      label: "Sales/Marketing",
      accountNumber: "70440",
      value: "736",
    },
    { id: "737", label: "IT/Technik", accountNumber: "70450", value: "737" },
    {
      id: "739",
      label: "Still Operator",
      accountNumber: "70115",
      value: "739",
    },
  ];
  const constcenter2 = [
    {
      id: "738",
      label: "Kunden-übergreifend / Sonstiges",
      accountNumber: "709005",
      value: "738",
    },
    {
      id: "740",
      label: "S.Oliver Produktfoto",
      accountNumber: "700021",
      value: "740",
    },
    {
      id: "741",
      label: "Comma Produktfoto",
      accountNumber: "700031",
      value: "741",
    },
    {
      id: "742",
      label: "Liebeskind Produktfoto",
      accountNumber: "700041",
      value: "742",
    },
    {
      id: "743",
      label: "Görtz Produktfoto",
      accountNumber: "700051",
      value: "743",
    },
    {
      id: "744",
      label: "PUMA Produktfoto",
      accountNumber: "700061",
      value: "744",
    },
    {
      id: "745",
      label: "PUMA Kampagne",
      accountNumber: "700062",
      value: "745",
    },
    {
      id: "746",
      label: "TomTailor Produktfoto",
      accountNumber: "700071",
      value: "746",
    },
    {
      id: "747",
      label: "TomTailor Kamagne",
      accountNumber: "700072",
      value: "747",
    },
    {
      id: "748",
      label: "BonPrix Produktfoto",
      accountNumber: "700091",
      value: "748",
    },
    {
      id: "749",
      label: "HFG Produktfoto",
      accountNumber: "700101",
      value: "749",
    },
    { id: "750", label: "HFG Kampagne", accountNumber: "700102", value: "750" },
    {
      id: "751",
      label: "Juvia Produktfoto",
      accountNumber: "700111",
      value: "751",
    },
    {
      id: "752",
      label: "HugoBoss Produktfoto",
      accountNumber: "700121",
      value: "752",
    },
    {
      id: "753",
      label: "HugoBoss Kampagne",
      accountNumber: "700122",
      value: "753",
    },
    {
      id: "754",
      label: "Bogner Produktfoto",
      accountNumber: "700601",
      value: "754",
    },
    {
      id: "755",
      label: "Bogner Kampagne",
      accountNumber: "700602",
      value: "755",
    },
    {
      id: "756",
      label: "Heine Produktfoto",
      accountNumber: "700621",
      value: "756",
    },
    {
      id: "757",
      label: "WittWeiden Produktfoto",
      accountNumber: "705071",
      value: "757",
    },
    {
      id: "758",
      label: "Zalando EPS Produktfoto",
      accountNumber: "705141",
      value: "758",
    },
    {
      id: "759",
      label: "Zalando EPS Kampagne",
      accountNumber: "705142",
      value: "759",
    },
    {
      id: "760",
      label: "ToryBurch Produktfoto",
      accountNumber: "705171",
      value: "760",
    },
    {
      id: "761",
      label: "Falke Produktfoto",
      accountNumber: "705181",
      value: "761",
    },
    {
      id: "762",
      label: "Zalando RPDP Produktfoto",
      accountNumber: "705221",
      value: "762",
    },
    {
      id: "763",
      label: "Sonstige Kleinkunden Produktfoto",
      accountNumber: "707991",
      value: "763",
    },
    {
      id: "766",
      label: "PUMA Virtual Production",
      accountNumber: "700066",
      value: "766",
    },
    {
      id: "767",
      label: "C&A Produktfoto",
      accountNumber: "703421",
      value: "767",
    },
    { id: "768", label: "C&A Kampagne", accountNumber: "703422", value: "768" },
    {
      id: "769",
      label: "C&A Virtual Production",
      accountNumber: "703429",
      value: "769",
    },
    {
      id: "773",
      label: "Ziener Produktfoto",
      accountNumber: "705051",
      value: "773",
    },
    {
      id: "772",
      label: "Zalando EPDP Produktfoto HH",
      accountNumber: "705191",
      value: "772",
    },
    {
      id: "778",
      label: "Tommy Hilfiger Produktfoto",
      accountNumber: "740411",
      value: "778",
    },
  ];

  const bookingStatusOptions = [
    { value: "Booked", label: "booked / Lano task created" },
    { value: "confirmed", label: "confirmed" },
    { value: "new order", label: "new order" },
    { value: "requested", label: "requested" },
    { value: "declined request", label: "declined request" },
    { value: "declined", label: "declined" },
    { value: "canceled", label: "canceled" },
    { value: "optioned", label: "optioned" },
    { value: "optionSolved", label: "option released" },
    { value: "talentOnHold", label: "1. Option" },

    // ... other statuses
  ];

  const handleSelectCostCenter = (selectedOption) => {
    setSelecedCostcenter(selectedOption);
  };
  const handleSelectCostCentertwo = (selectedOption) => {
    setSelecedCostcentertwo(selectedOption);
  };


  const fetchCompanyNames = async () => {
    const companySnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"));
    const companyData = companySnap.docs.map(doc => doc.data().name);
    setCompanies(companyData);
  };

  const companyOptions = companies.map(company => ({
    value: company,
    label: company
  })).sort((a, b) => a.label.localeCompare(b.label));

  const options = [
    { value: 'new order', label: 'New order' },
    { value: 'optioned', label: 'Optioned' },
    { value: 'talentaccepted', label: 'Accepted by talent' },
    { value: 'confirmed', label: 'Confirmed' }
  ];

  const position = [
    { value: 'HM', label: 'Hair & Make-Up' },
    { value: 'AD', label: 'Art Direction' },
    { value: 'foto', label: 'Photo' },
    { value: 'foto-assistent', label: 'Photo Assistant' },
    { value: 'video', label: 'Video' },
    { value: 'digi-op', label: 'Digi-Op' },
    { value: 'styling', label: 'Styling' },
    { value: 'styling-assistent', label: 'Styling Assistant' },
    { value: "LOGISTIK / WARENAUFBEREITUNG", label: "LOGISTIK / WARENAUFBEREITUNG" },
    { value: "PRODUCER", label: "Producer" },
    { value: "Art Buying", label: "Art Buying" },
    { value: "Account Management", label: "Account Management" }, 
  ];

  const onmodel = [
    { value: 'onmodel', label: 'On Model' },
    { value: 'Produkt', label: 'On Product' },
  ];

  const bookingoptions = [
    { value: 'booking', label: 'Booking' },
    { value: 'option', label: 'Option' },
  ];

  useEffect(() => {
    fetchCompanyNames();
    fetchlocations();
  }, []);

  const handleCompanyChange = (selectedOption) => {
    setSelectedCompany(selectedOption);
  };

  const handlesetSelect = (selectedOption) => {
    setSet(selectedOption);
  };

  const handleTalentChange = (selectedOption) => {
    setSelectedTalent(selectedOption);
  };

  const handleClientChange = (selectedOption) => {
    setClient(selectedOption);
    if (selectedOption.value === 'Set Cast 1. Option') {
      sethideinfos(true);
      setSelecedCostcenter(" "); // Clear the first cost center
      setSelecedCostcentertwo(" "); // Clear the second cost center
    }else{
      sethideinfos(false);
    }
  };

  const handleOptionChange = (selectedOption) => {
    setbookingoption(selectedOption);
  };

  const handlePostionchange = (selectedOption) => {
    setSelectedPosition(selectedOption);
  };
  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newBookingStartDate = new Date(startDate);
    const newBookingEndDate = new Date(endDate);
    const currentDateTime = new Date(); 

  
  
      const newBooking = {
        start_date: selectedDate,
        end_date: selectedDate,
        status: status.value,
        title: title,
        kpi: kpi,
        set: selectedTalentSet,
        position: {label:selectedFunktion, valie:selectedFunktion},
        client: client,
        sinfo: sinfo,
        studio: description,
        costcenter1: selectedCostCenter,
        costcenter2: selectedCostCentertwo,
        createdBy: localStorage.getItem("userEmail"),
        proposedBy: localStorage.getItem("userEmail"),
        createdAt: Timestamp.fromDate(currentDateTime),
        startTime: startTime,
        endTime: endTime,
        location: selectedLocations.value,
        talentId: selectedTalentId,
      };
    
      await addDoc(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"), newBooking);
      if (isChecked1) {
      await createmailtalentcomfirmed(newBooking);
      }
      alert("Booking updated successfully.");
    
      window.location.reload();
    
    //onClose();
  };
  const createmailtalentcomfirmed = async ( newBooking) => {
 
  
    const msg = {
      to: ' booking@set-cast.com, '+  selectedTalentEmail,
      message: {
        subject: `${status.value}- ${client.label} -  ${selectedTalentName}`,
        text: `${JSON.stringify(newBooking)}`,
        html: `
        Dear  ${selectedTalentName} ,
        
        
       
      
     
        <p> STATUS: ${status.value}</p>
        <p>  FUNCTION:  ${selectedFunktion} </p>
        <p>  CLIENT:  ${client.label} </p>
        <p>  Set:  ${selectedTalentSet} </p>
        <p>  DATE/PERIOD:  ${formattedDate} </p>
        <p>  TIME:  ${startTime} -${endTime} </p>
        <p>  DAYRATE:  ${talentRate} </p>
        <p>  KPI:  ${kpi} </p>
        <p>  LOCATION:  ${selectedLocations.value} </p>
     
        <p>SETCast contact person:  ${localStorage.getItem("userEmail")} </p>
        <p> 
        Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
       
        <p>  LEGENDE:</p>
        <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
        <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
        <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
        <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
        
        </p>`,
      },
    };
   
    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };
  

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" >
      <div className="modal-container">
        <button className="modal-close" onClick={onClose}>X</button>
        <h3>Create Order</h3>

      <p> FUNCTION:
        {selectedFunktion}</p> 
      <p>set:{selectedTalentSet}</p>  
       <p>  DATE:
             {formattedDate}</p>
             <p>  Talent:
             {selectedTalentName}</p>
             <p>  Rate:
             {talentRate}</p>
     
        <form onSubmit={handleSubmit}>
          <Select
            components={{ DropdownIndicator }}
            options={companyOptions}
            onChange={handleClientChange}
            placeholder="Client"
            value={client}
            styles={customStyles}
          />
           {!hideinfos && (
            <>
                  <div >
                      <Select
                        components={{ DropdownIndicator }}
                        options={constcenter1}
                        onChange={handleSelectCostCenter}
                        placeholder="CostCenter 1"
                        value={selectedCostCenter}
                        styles={customStyles}
                        
                      />
                    </div>
                    <div>
                      <Select
                        components={{ DropdownIndicator }}
                        options={constcenter2}
                        onChange={handleSelectCostCentertwo}
                        placeholder="CostCenter 2"
                        value={selectedCostCentertwo}
                        styles={customStyles}
                        
                      />
                    </div>
                    <input type="text" class="inputtextmodal" value={kpi} onChange={(e) => setKpi(e.target.value)}  placeholder='KPI'/>

<input type="text" class="inputtextmodal" value={description} onChange={(e) => setDescription(e.target.value)}  placeholder='Studio'/>

<input type="text" class="inputtextmodal" value={sinfo} onChange={(e) => setSinfo(e.target.value)} placeholder='S. Info'/>
                    </>
          )}

                    <Select
        value={status}
        onChange={handleStatusChange}
        options={bookingStatusOptions}
        isClearable
        placeholder="Select Status"
        styles={customStyles}
      />
          <Select
            value={selectedLocations}
            onChange={setSelectedLocations}
            options={locationOptions}
            isClearable
            placeholder="Locations"
            styles={customStyles}
          />

          
                  
          <input type="text" class="inputtextmodal" value={startTime} onChange={(e) => setStartTime(e.target.value)} required placeholder='Start Time'/>
          <input type="text" class="inputtextmodal" value={endTime} onChange={(e) => setEndTime(e.target.value)} required placeholder='End Time'/>
          <div style={{ display: 'flex' }}>
          <div className="custom-checkbox" style={{marginBottom: '20px'}}>
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      checked={isChecked1}
                      onChange={() => setIsChecked1(!isChecked1)}
                    />
                    <label htmlFor="checkbox-1"> send Email notification to Talent</label>
                  </div>
          
          </div>
                     
         
          <button className='button' onClick={handleSubmit}>Create Order</button>
          <button className='button' onClick={onClose}>close</button>
        </form>
      </div>
    </div>
  );
};

export default BookingModal;
