import React, { useState } from 'react';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc,Timestamp } from "firebase/firestore";
import { useLocation } from 'react-router-dom';

import confirmIcon from '../../images/confirm.svg';
import declineIcon from '../../images/decline.svg';
import { auth, db } from './../../firebaseConfig';
const ListViewRequested = ({ events, authToken }) => {
  const [allbookingdata, setallbookingdata]=useState();

    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const fetchBooking = async (bookingId) => {
      const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
      const bookingDoc = await getDoc(bookingRef);
  

      setallbookingdata(bookingDoc.data());


      if (!bookingDoc.exists) {
          console.error("Booking not found");
          return null;
      }

      return bookingDoc.data();
  };

  // Fetch talent based on talentId
  const fetchTalent = async (talentId) => {
      const talentRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
      const talentDoc = await getDoc(talentRef);

      if (!talentDoc.exists) {
          console.error("Talent not found");
          return null;
      }

      return talentDoc.data();
  };
  const fetchBookingsByGroup = async (groupId) => {
    const bookingsRef = collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings");
    const q = query(bookingsRef, where("group", "==", groupId));
    const querySnapshot = await getDocs(q);
    let bookings = [];
    querySnapshot.forEach((doc) => {
        bookings.push({ id: doc.id, start:doc.data().start_date, ...doc.data() });
    });
    return bookings;
  };
  
  const acceptporposedtalent = async (bookingId) => {

        const booking = await fetchBooking(bookingId);
        const talent = await fetchTalent(booking.talentId);
        // Check if it's a new booking or an update
        if (bookingId) {
            // Update existing booking
     
            try {
                const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                
                const bookingSnapshot = await getDoc(bookingRef);
                const bookingData = bookingSnapshot.data();
                if (bookingData.isdummy) {
                  await updateDoc(bookingRef, {
                    status: 'talentOnHold',  // Please note: 'comfirmed' seems like a typo, it should be 'confirmed'
                });
             
              } else { 

               await updateDoc(bookingRef, {
                    status: 'optioned',  // Please note: 'comfirmed' seems like a typo, it should be 'confirmed'
                });}
               // createmailtalentcomfirmed(booking, talent, allbookingdata);
           
             

         
               createmailtalentcomfirmed(booking, talent, allbookingdata);
                alert("Booking updated successfully.");
              //  createTask(booking, talent);
            //   createmailcomfirmed(booking, talent);
          // window.location.reload();
               
            } catch (error) {
                console.error("Error updating booking: ", error);
            }
        
    } else {
        console.log("You have cancelled the operation.");
    }
}

const acceptProposedTalentForGroup = async (groupId) => {
  try {
    const bookings = await fetchBookingsByGroup(groupId);
    for (const booking of bookings) {
      await acceptporposedtalent(booking.id);
    }
    window.location.reload();
  } catch (error) {
    console.error("Error in acceptProposedTalentForGroup:", error);
  }
};


const declineProposedTalentForGroup = async (groupId) => {
  const bookings = await fetchBookingsByGroup(groupId);
  for (const booking of bookings) {
    await declineproposedtalent(booking.id); // Assuming this function is now adapted to only require booking ID
  }
  // Optionally: Handle notifications or UI updates here
};

    const declineproposedtalent = async (bookingId)=>{
   
      const booking = await fetchBooking(bookingId);
      const talent = await fetchTalent(booking.talentId);

        // Check if it's a new booking or an update
        if (bookingId) {
          // Update existing booking
          try {
            const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
            const bookingSnapshot = await getDoc(bookingRef);
            const bookingData = bookingSnapshot.data();
              await updateDoc(bookingRef, {
    
                status: 'deleted',
                group:null,
                groupColor:'',
                SystemNotes: 'declined request',
              });
              const newBooking = {
                ...bookingData,
                status: 'new order',
                talentId:'',
                group:null,
                groupColor:'',
                statusUpdatedAt: Timestamp.now(),
              };
              delete newBooking.id; // Remove the id property since Firestore will automatically generate a new one
              if (!bookingData.isdummy) {
              const  newBookingRef=  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"), newBooking);
                const newBookingId = newBookingRef.id; // Ta-da! The ID of the new booking.

              await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
              {
                userId: bookingData.processingBy,
                message: `Talent ${talent.Name} rejected the order for ${bookingData.client.label}. Please propose another talent`,
                read: false,
                bookingId: bookingId,
                url:'admin/allnewbookings',
                createdAt: Timestamp.now()
              })
       

           
           
              }
              alert("Booking declined successfully.");
            //  createmaildeclined(booking, talent);
         
            window.location.reload();
          } catch (error) {
            console.error("Error updating booking: ", error);
          }
        
      } else {
        console.log("You have cancelled the operation.");
    }

}

const createmailcomfirmed = async (filteredBooking, talent, start) => {

  const msg = {
    to: 'booking@set-cast.com, ' + talent.E_Mail,
    message: {
      subject: 'Planning Orendt has accepted the booking ',
      text: ` ${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  Team,
      
      
      <p>Planning Orendt has accepted the booking and the Order has been confirmed</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};


const createmailtalentcomfirmed = async (filteredBooking, talent, allbookingdata) => {
  const rate = filteredBooking?.customRate ?? talent.Tagessatz;

  const timestampInSeconds = filteredBooking.start_date; // Replace with actual timestamp or variable

// Create a new Date object from the timestamp
const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

// Format the date as dd.mm.yyyy
const formattedDate = date.getDate().toString().padStart(2, '0') + '.' +
                      (date.getMonth() + 1).toString().padStart(2, '0') + '.' + // Months are zero-indexed
                      date.getFullYear();

  const msg = {
    to: ' booking@set-cast.com, '+  talent.E_Mail,
    message: {
      subject: `1. Option -   ${filteredBooking.client.label} -  ${talent.Name}`,
      text: `${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  ${talent.Name} ,
      
      
      <p>as we have just discussed on the phone, I have noted the 1st option on you for the following production:</p>
    
   
      <p> STATUS: 1ST OPTION </p>
      <p>  FUNCTION:  ${filteredBooking.position.label} </p>
      <p>  CLIENT:  ${filteredBooking.client.label} </p>
      <p>  Set:  ${filteredBooking.set} </p>
      <p>  DATE/PERIOD:  ${formattedDate} </p>
      <p>  TIME:  ${filteredBooking.startTime} -${filteredBooking.endTime} </p>
      <p>  DAYRATE:  ${rate} </p>
      <p>  KPI:  ${filteredBooking.kpi} </p>
      <p>  LOCATION:  ${filteredBooking.studio} - ${filteredBooking.location} </p>
   
      <p>SETCast contact person:  ${filteredBooking.proposedBy} </p>
      <p> 
      Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
     
      <p>  LEGENDE:</p>
      <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
      <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
      <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
      <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};

const createmaildeclined = async (filteredBooking, talent, start) => {

  const msg = {
    to:   ' booking@set-cast.com, talent@mondula.com' +  talent.E_Mail,
    message: {
      subject: 'Planning Orendt has declined the booking ',
      text: `${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  Team,
      
      
      <p>Planning Orendt has declined the booking!</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};


function getWeekNumber(d) {
  // Copy date so don't modify the original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  
  // Get first day of the year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  
  return weekNo;
}

const createTask = async (booking, talent) => {
  console.log('create called' +authToken)
  // add entity - POST
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + authToken);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Access-Control-Allow-Origin', 'http://dev-setcast.mondula.com/');
  myHeaders.append('Access-Control-Allow-Credentials', 'true');
  const kw ='KW'+ getWeekNumber(new Date(booking.start_date.seconds * 1000)) + " " ;
 var titile= kw+'_' +booking.client.label+'_'+booking.position.label+'_'+new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0]+'-'+new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0];

  // Replace hardcoded data with the booking details
  var raw = JSON.stringify({
    "task": {
      "title": titile,
      "description": booking.description || ' ',
      "details": booking.description || ' ',
      "starts_on": new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0],
      "ends_on": new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0],
      "rate": talent.Tagessatz,
      "duration_in_time_units": booking.range,
      "currency": "EUR",
      
      "budget_setting": "set_budget",
      "assignee_id": talent.Lano_ID,
      "rate_type_id": 1,
      "creator_id": 216149,
      "state": "freelancer_assigned",
      "task_cost_centers_attributes": [
        {
            "cost_center_1_id": booking.costcenter1.id,
            "cost_center_2_id": booking.costcenter2.id
        }
    ]
    }
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'manual'
  };

  fetch("https://api.lano.io/api/v1/tasks", requestOptions)
    .then(response => response.text())
    .then(result => { console.log(result); /* setMeddage(result); */ }) // Typo fix: it should probably be setMessage
    .catch(error => console.log('error', error));
}

const processedGroups = new Set();

    const filteredEvents = events.filter(
        event =>
            event.boktitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
            event.set.toLowerCase().includes(searchTerm.toLowerCase()) ||
           
            event.talent.toLowerCase().includes(searchTerm.toLowerCase()) ||
            event.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
            event.company.toLowerCase().includes(searchTerm.toLowerCase())
    );
 

    return (
        <div>
          <div></div>
            <div style={{ marginTop: '40px', marginBottom: '60px' }}>
              <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                  className='inputtext search'
              />
            </div>

            <div className='content-wrapper content-wrapper-talent-list'>
                <div className='list-tasks-header'>
                 
                    <div style={{ flexBasis: '200px' }}>start/end</div>
                    <div style={{ flexBasis: '150px' }}>set</div>
                    <div style={{ flexBasis: '50px' }}>kpi</div>
                    <div style={{ flexBasis: '150px' }}>pos.</div>
                    <div style={{ flexBasis: '100px' }}>option</div>
                    <div style={{ flexBasis: '150px' }}>talent</div>
                    <div style={{ flexBasis: '100px' }}>status</div>
                    <div style={{ flexBasis: '300px' }}>client</div>

                    <div style={{ flexBasis: '80px' }}></div>
                </div>
            </div>

            {filteredEvents.map((event, index) => {
    const shouldDisplayIcon = true;
    /*!event.group || !processedGroups.has(event.group);
    if (event.group) {
        processedGroups.add(event.group);
    }*/

    async function handleAccept() {
      const userConfirmed = window.confirm("Are you sure you want to comfirm?");
      if (userConfirmed) {
    //  if (event.group) {
     //   await acceptProposedTalentForGroup(event.group);
    //  } else {
        acceptporposedtalent(event.bookingId); // Assuming this is intentionally not awaited
     // }
    }
    }

    async function handleDecline() {
      const userConfirmed = window.confirm("Are you sure you want to reject this order?");
      if (userConfirmed) {
      if (event.group) {
        await declineProposedTalentForGroup(event.group);
      } else {
        declineproposedtalent(event.bookingId); // Assuming this is intentionally not awaited
      }
    }
    }


              return(
              
                <div className='list-tasks-item' key={event.id}>
        
                  <div style={{ flexBasis: '200px' }}>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</div>
                  <div style={{ flexBasis: '150px' }}>{event.set}</div>
                  <div style={{ flexBasis: '50px' }}>{event.kpi}</div>
                  <div style={{ flexBasis: '150px' }}>{event.position}</div>
                  <div style={{ flexBasis: '100px' }}>{event.option}</div>
                  <div style={{ flexBasis: '150px' }}>{event.talent}</div>
                  <div style={{ flexBasis: '100px' }}>{event.status}</div>
                  <div style={{ flexBasis: '300px' }}>{event.client}</div>
                  {shouldDisplayIcon && (
                  <div style={{ flexBasis: '80px' }}>
                    <a className='edit-button' onClick={()=>handleAccept()} style={{ marginRight: '20px' }}>
                      <img src={confirmIcon} alt="Accept" className="edit-container" />
                    </a>
                    <a className='edit-button' onClick={()=>handleDecline ()}>
                      <img src={declineIcon} alt="Decline" className="edit-container" />
                    </a>
                  </div>
                    )}
              </div>    );
})}
<           div style={{ marginTop: '80px' }}>
              <p><i>Note: Accepting or declining the option is on behalf of the talent.</i></p>
            </div>

        </div>
    );
};

export default ListViewRequested;
