import React, { useState } from 'react';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc } from "firebase/firestore";
import { useLocation } from 'react-router-dom';

import confirmIcon from '../../images/confirm.svg';
import declineIcon from '../../images/decline.svg';
import { auth, db } from './../../firebaseConfig';
const ListViewConfirmed = ({ events, authToken }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const processedGroups = new Set();

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const fetchBooking = async (bookingId) => {
      const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
      const bookingDoc = await getDoc(bookingRef);

      if (!bookingDoc.exists) {
          console.error("Booking not found");
          return null;
      }

      return { id: bookingDoc.id, ...bookingDoc.data() };
    };

    const fetchBookingsByGroupId = async (groupId) => {
      const bookingsRef = collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings");
      // Query for bookings that match the groupId and have a "confirmed" status
      const q = query(bookingsRef, where("group", "==", groupId), where("status", "==", "confirmed"));
    
      const querySnapshot = await getDocs(q);
      let bookings = [];
      querySnapshot.forEach((doc) => {
        bookings.push({ id: doc.id, ...doc.data() });
      });
      return bookings;
    };
    



  // Fetch talent based on talentId
  const fetchTalent = async (talentId) => {
      const talentRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
      const talentDoc = await getDoc(talentRef);

      if (!talentDoc.exists) {
          console.error("Talent not found");
          return null;
      }

      return talentDoc.data();
  };
  const acceptporposedtalent = async (bookingId) => {
    const userConfirmed = window.confirm("Are you sure you want to confirm?");
    if (userConfirmed) {
      try {
        const booking = await fetchBooking(bookingId);
        let bookingsToUpdate = [];
  
        if (booking.group !== null && booking.group !== undefined && booking.group !== '') {
          const groupBookings = await fetchBookingsByGroupId(booking.group);
          bookingsToUpdate = groupBookings;
        } else {
          bookingsToUpdate.push(booking);
        }
  
        // Check the status of all bookings to ensure they are still confirmed
        bookingsToUpdate = await Promise.all(
          bookingsToUpdate.map(async (b) => {
            const currentBooking = await fetchBooking(b.id);
            if (currentBooking.status !== 'confirmed') {
              console.warn(`Booking ${b.id} status has changed to ${currentBooking.status}.`);
              return null; // Return null for bookings that are no longer confirmed
            }
            return currentBooking;
          })
        );
  
        // Filter out any null entries (bookings that are no longer confirmed)
        bookingsToUpdate = bookingsToUpdate.filter(b => b !== null);
  
        // If there are no bookings left to update, reload the page with an error
        if (bookingsToUpdate.length === 0) {
          alert("All bookings in the group have changed status. Please refresh the page.");
          window.location.reload();
          return;
        }
  
        // Proceed with the updates if all bookings are still confirmed
        for (const b of bookingsToUpdate) {
          const talent = await fetchTalent(b.talentId);
          try {
            await createTask(b.id, b, talent); // Wait for the task creation to complete
  
            // After the task is created successfully:
            await updatestategroup(b.id, b); // Update the booking
            console.log(b.id, b);
  
            // Call your email sending function here
           
          // if (b.set.toLowerCase() === "on model" && (b.location.includes("B28") || b.location.includes("TR4"))) {
           //   console.log('callsheets is true')
            //  await createmailtalentcomfirmedscallsheets(b, talent);

            //} else { 
              
              await createmailtalentcomfirmeds(b, talent);
           // } 
           // await createmailtalentcomfirmeds(b, talent)
          } catch (error) {
            // Handle error for individual booking
            console.error(`Error processing booking ${b.id}:  `, error);
            alert(`LANO: An error occurred while processing the booking ${b.id}. Please try again.`);
            window.location.reload(); // Reload the page on error
            return; // Exit the function to prevent further execution
          }
        }
  
        // If all tasks were created successfully and updates/emails were sent
        alert("Booking(s) updated and Lano task(s) created successfully.");
        window.location.reload();
      } catch (error) {
        console.error("Error processing booking(s): ", error);
        alert(`An error occurred: ${error.message}. Please try again.`);
        window.location.reload(); // Reload the page on error
      }
    } else {
      console.log("Operation cancelled.");
    }
  };
  

const updateBookings = async (bookingIds, updateData) => {
  for (const id of bookingIds) {
    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", id);
    await updateDoc(bookingRef, updateData);
  }
};
const declineproposedtalent = async (bookingId) => {
  const userConfirmed = window.confirm("Are you sure you want to reject this order?");
  if (userConfirmed) {
    try {
      const booking = await fetchBooking(bookingId);
      let bookingsToUpdate = [];

      if (booking.group) {
        const groupBookings = await fetchBookingsByGroupId(booking.group);
        bookingsToUpdate = groupBookings;
      } else {
        bookingsToUpdate.push(booking);
      }

      // Check the status of all bookings to ensure they are still confirmed
      bookingsToUpdate = await Promise.all(
        bookingsToUpdate.map(async (b) => {
          const currentBooking = await fetchBooking(b.id);
          if (currentBooking.status !== 'confirmed') {
            console.warn(`Booking ${b.id} status has changed to ${currentBooking.status}.`);
            return null; // Return null for bookings that are no longer confirmed
          }
          return currentBooking;
        })
      );

      // Filter out any null entries (bookings that are no longer confirmed)
      bookingsToUpdate = bookingsToUpdate.filter(b => b !== null);

      // If there are no bookings left to update, reload the page with an error
      if (bookingsToUpdate.length === 0) {
        alert("All bookings in the group have changed status. Please refresh the page.");
        window.location.reload();
        return;
      }

      // Proceed with the updates if all bookings are still confirmed
      const bookingIdsToUpdate = bookingsToUpdate.map(b => b.id);

      await updateBookings(bookingIdsToUpdate, { status: 'declined' });
      alert("Booking(s) declined successfully.");
      window.location.reload();
    } catch (error) {
      console.error("Error declining booking(s): ", error);
      alert(`An error occurred: ${error.message}. Please try again.`);
      window.location.reload(); // Reload the page on error
    }
  } else {
    console.log("Operation cancelled.");
  }
};


const createmailcomfirmed = async (filteredBooking, talent, start) => {
  const rate = filteredBooking.customDayRate ?? talent.Tagessatz;
  const msg = {
    to: 'booking@set-cast.com, talent@mondula.com,' +localStorage.getItem("userEmail"),
    message: {
      subject: 'Planning Orendt has accepted the booking ',
      text: ` ${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  Team,
      
      
      <p>Planning Orendt has accepted the booking and the Order has been confirmed</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};


const createmailtalentcomfirmed = async (filteredBooking, talent, start) => {

  const msg = {
    to: localStorage.getItem("userEmail")+', booking@set-cast.com, talent@mondula.com,'+  talent.E_Mail,
    message: {
      subject: 'Planning Orendt has accepted the booking ',
      text: `${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  ${talent.Name} ,
      
      
      <p>Planning Orendt has accepted the booking and you are booked for:</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.Name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};

const createmaildeclined = async (filteredBooking, talent, start) => {

  const msg = {
    to:  localStorage.getItem("userEmail")+ ', booking@set-cast.com, talent@mondula.com' +  talent.E_Mail,
    message: {
      subject: 'Planning Orendt has declined the booking ',
      text: `${JSON.stringify(filteredBooking)}`,
      html: `
      Dear  Team,
      
      
      <p>Planning Orendt has declined the booking!</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/ </a>
     
      
      
      </p>`,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};


function getWeekNumber(d) {
  // Copy date so don't modify the original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  
  // Get first day of the year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  
  return weekNo;
}

const createTask = async (bookingId, booking, talent) => {
  console.log('create called' +authToken)
  const rate = booking.customRate ?? talent.Tagessatz;

  return new Promise((resolve, reject) => {

  // add entity - POST
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + authToken);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Access-Control-Allow-Origin', 'http://dev-setcast.mondula.com/');
  myHeaders.append('Access-Control-Allow-Credentials', 'true');
  const kw ='KW'+ getWeekNumber(new Date(booking.start_date.seconds * 1000)) + " " ;
 var titile= kw+'_' +booking.client.label+'_'+booking.position.label+'_'+talent.Name+'_'+new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0]+'-'+new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0]+'_'+booking.location;

  // Replace hardcoded data with the booking details
  var raw = JSON.stringify({
    "task": {
      "title": titile,
      "description": booking.description || ' ',
      "details": booking.description || ' ',
      "starts_on": new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0],
      "ends_on": new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0],
      "rate": rate,
      "duration_in_time_units": 1,
      "currency": "EUR",
      
      "budget_setting": "set_budget",
      "assignee_id": talent.Lano_ID,
      "rate_type_id": 2,
      "creator_id": 220834,
      "state": "freelancer_assigned",
      "task_cost_centers_attributes": [
        {
            "cost_center_1_id": booking.costcenter1.id,
            "cost_center_2_id": booking.costcenter2.id
        }
    ]
    }
  });
  console.log(raw);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'manual'
  };

  fetch("https://api.lano.io/api/v1/tasks", requestOptions)
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.text();
  })
  .then(result => {
    resolve(result); // Resolve the promise with the result
  })
  .catch(error => {
    console.log('error', error);
    reject(error); // Reject the promise on error
  });
});
};

const updatestategroup =async(bookingId, booking)=>{
  console.log('Updating state for bookingId:', bookingId);

  const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", booking.id);
  await updateDoc(bookingRef, {
      status: 'Booked',  
  });

 // alert("Lano task created successfully.");
 

}

const updatestate =async(bookingId, booking)=>{
  console.log('Updating state for bookingId:', bookingId);

  const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", booking.id);
  await updateDoc(bookingRef, {
      status: 'Booked',  
  });

  alert("Lano task created successfully.");
 
 window.location.reload();
}


const createmailtalentcomfirmeds = async (booking, talent) => {
  const rate = booking.customRate ?? talent.Tagessatz;
  const msg = {
    to: ' booking@set-cast.com, '+  talent.E_Mail,
    message: {
      subject: `Planning Orendt has accepted the booking -  ${booking.client.label} - ${talent.Name} `,
      text: `${JSON.stringify(booking)}`,
      html: `
      Dear  ${talent.Name} ,
      
      
      <p>We are happy to confirm you for the following production. Please find below the details:
      </p>
    
      <p>STATUS: BOOKED</p>
      <p>Talent: ${talent.Name}</p>
      <p>FUNCTION:   ${booking.position.label}</p>
      <p>CLIENT:   ${booking.client.label}</p>
      <p>SET:  ${booking.set}</p>
      <p>DATE/PERIOD::    <span>${new Date(booking.start_date.toDate())}</span></p>
      <p>DAYRATE:   ${rate}</p>
      <p>KPI :  ${booking.kpi} </p>
      <p>CALLTIME:  ${booking.startTime} - ${booking.endTime} </p>
      <p>LOCATION:  ${booking.location} </p>

      <p>SETCast contact person:  ${booking.proposedBy} </p>
      </br></br>
      <p> <b> In case of illness or delay, please send an email directly to the distribution list planning@orendtstudios.com and krank@set-cast.com by 8:00 a.m. at the latest.</b> </p>
      </br></br>
 
      <p><b> Please make sure to inform Set-Cast and the producer of the production if you need to leave the set early and double check with the producer if the KPI has been reached.</b> </p>
      </br></br>

      <p>For questions regarding KPI, shooting schedules, callsheets and briefings, please contact planning@orendtstudios.com.</p>
      </br></br>
      <p><b>  Invoice details:</b>  </p>
      <p> please upload your invoice in Lano - you could combine several tasks in one invoice</p>
      
      </br>
      <p>We wish you a successful production.</p>
      <p>Warm regards,</p>
      <p>Talents Management Team</p>
      </br>
      <p> +49 40 39 834 667</p>
      <p>  talents@set-cast.com</p>
      <p> <b>  LEGENDE:</b> </p>
      <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
      <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
      <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
      <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
      



      `,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};



const createmailtalentcomfirmedscallsheets = async (booking, talent) => {
  const rate = booking.customRate ?? talent.Tagessatz;
  const msg = {
    to: ' booking@set-cast.com, fitore.gashi@mondula.com, '+  talent.E_Mail,
    message: {
      subject: `Planning Orendt has accepted the booking -  ${booking.client.label} - ${talent.Name} `,
      text: `${JSON.stringify(booking)}`,
      html: `
      Dear  ${talent.Name} ,
      
      
      <p>we are happy to confirm you for the following production. 
      Please find below the details:
      </p>
    
      <p>STATUS: BOOKED</p>
      <p>Talent: ${talent.Name}</p>
      <p>FUNCTION:   ${booking.position.label}</p>
      <p>CLIENT:   ${booking.client.label}</p>
      <p>SET:  ${booking.set}</p>
      <p>DATE/PERIOD::    <span>${new Date(booking.start_date.toDate())}</span></p>
      <p>DAYRATE:   ${rate}</p>
      <p>KPI :  ${booking.kpi} </p>
      <p>CALLTIME:  ${booking.startTime} - ${booking.endTime} </p>
      <p>LOCATION:  ${booking.location} </p>

      <p>SETCast contact person:  ${booking.proposedBy} </p>

</br></br>
      <p><b> CALLSHEET:</b>  </p>

      <p>From now on there are no more callsheets for the standard Studio on Model productions.</p>

      <p>Please take the information from this confirmation:</p>

      <p>HAIR AND MAKE UP start time = call time</p>

      <p>STYLING start time = call time + 15 minutes</p>

      <p>PHOTOGRAPHER start time = call time + 30 minutes</p>

      <p>AD = start time = call time + 30 minutes</p>

      <p>Hair and Make Up receives current polas of the model(s) separately by mail via producing- unit</p>

 

      </br></br>



      <p>For questions regarding KPI, shooting schedules and briefings, please contact planning@orendtstudios.com.</p>


      </br>
      <p> In case of illness, please send an email directly to the distribution list planning@orendtstudios.com and krank@set-cast.com by 8:00 a.m. at the latest.</p>
   
      <p><b>  Invoice details:</b>  </p>
      <p> please upload your invoice in Lano - you could combine several tasks in one invoice</p>
      
      </br></br>
      <p> We wish you a successful production.</p>
      <p>  Warm regards,</p>
      <p>  Talents Management Team</p>
    
      <p> +49 40 39 834 667</p>
      <p>  talents@set-cast.com</p>
      <p>  <b> LEGENDE: <b/> </p>
      <p>  B28 = Behringstrasse 28a, 22765 Hamburg</p>
      <p>  TR4 = Tarpenring 4, 22419 Hamburg</p>
      <p>  M= Holy-Allee 3, Metzingen , am Empfang melden</p>
      <p>  OSB= Am Rosenkothen 2, 40880 Ratingen</p>
      



      `,
    },
  };
 
  await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
};



const filteredEvents = events.filter(
  event =>
      event.boktitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.set.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.kpi.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.talent.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.company.toLowerCase().includes(searchTerm.toLowerCase())
);
const groupedEvents = filteredEvents.reduce((acc, event) => {
  const key = event.group || event.id; // Use event.group as key; fallback to event.id if no group.
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(event);
  return acc;
}, {});


    return (
      <div>
          <div></div>
          <div style={{ marginTop: '40px', marginBottom: '60px' }}>
              <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                  className='inputtext search'
              />
          </div>

          <div className='content-wrapper content-wrapper-talent-list'>
              <div className='list-tasks-header'>
                  <div style={{ flexBasis: '150px' }}>start/end</div>
                  <div style={{ flexBasis: '150px' }}>set</div>
                  <div style={{ flexBasis: '50px' }}>kpi</div>
                  <div style={{ flexBasis: '150px' }}>pos.</div>
                  <div style={{ flexBasis: '100px' }}>Group</div>
                  <div style={{ flexBasis: '150px' }}>talent</div>
                  <div style={{ flexBasis: '100px' }}>status</div>
                  <div style={{ flexBasis: '200px' }}>client</div>
                  <div style={{ flexBasis: '100px' }}>s.info</div>
                  <div style={{ flexBasis: '120px' }}></div>
              </div>
          </div>

          {Object.entries(groupedEvents).map(([groupKey, eventsInGroup], groupIndex) => (
        <div key={groupKey} className='group-container'>
          {eventsInGroup.map((event, eventIndex) => (
            <div className='list-tasks-item' key={event.id}>
                    
                          <div style={{ flexBasis: '150px' }}>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</div>
                          <div style={{ flexBasis: '150px' }}>{event.set}</div>
                          <div style={{ flexBasis: '50px' }}>{event.kpi}</div>
                          <div style={{ flexBasis: '150px' }}>{event.position}</div>
                          <div style={{ flexBasis: '100px' }}>{event.group}</div>
                          <div style={{ flexBasis: '150px' }}>{event.talent}</div>
                          <div style={{ flexBasis: '100px' }}>{event.status}</div>
                          <div style={{ flexBasis: '200px' }}>{event.client}</div>
                          <div style={{ flexBasis: '100px' }}>{event.SonstigeInfo}</div>
                          {eventIndex !== 0 && (    < div style={{ flexBasis: '100px' }}></div>  )}
                          {eventIndex === 0 && (
                <div style={{ flexBasis: '100px' }}>
                  <a className='edit-button' onClick={() => acceptporposedtalent(event.id)} style={{ marginRight: '20px' }}>
                    <img src={confirmIcon} alt="Accept" className="edit-container" />
                  </a>
                  <a className='edit-button' onClick={() => declineproposedtalent(event.id)}>
                    <img src={declineIcon} alt="Decline" className="edit-container" />
                  </a>
                </div>
              )}
                      </div>
                  ))}
              </div>
          ))}

          <div style={{ marginTop: '80px' }}>
              <p><i>Note: Accepting will create a LANO task and book the talent.</i></p>
          </div>
      </div>
  );

};

export default ListViewConfirmed;