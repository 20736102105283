import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { useLocation } from 'react-router-dom';
import confirmIcon from '../../images/confirm.svg';
import declineIcon from '../../images/decline.svg';
import { auth, db } from './../../firebaseConfig';
import { customStyles } from './../../helpers/StyleUtils';
const TalentsOnHoldList = ({ events, authToken }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedKPI, setSelectedKPI] = useState(null);
    const [selectedGrup, setSelectedGrup] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedSet, setSelectedSet] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedTalent, setSelectedTalent] = useState(null);
    const [filterPreferences, setFilterPreferences] = useState([]);
    const [selectedPreferenceIndex, setSelectedPreferenceIndex] = useState(null); 
    const [selectedOrders, setSelectedOrders] = useState({});
    const [notifyTalent, setNotifyTalent] = useState(true);
    let userEmail = localStorage.getItem("userEmail");

    useEffect(() => {
        const fetchPreferences = async () => {
            if (!userEmail) return;

            const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
            const q = query(usersRef, where("email", "==", userEmail));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userData = querySnapshot.docs[0].data();
                const userPrefs = userData.filterPreferences;
                if (userPrefs) {
                    setFilterPreferences(userPrefs);
                }
            } else {
                console.log("No user or preferences found.");
            }
        };

        fetchPreferences();
    }, [userEmail]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    const handleCheckboxChange = (index) => {
        setSelectedOrders((prevSelected) => ({
          ...prevSelected,
          [index]: !prevSelected[index],
        }));
      
      };
    const fetchBooking = async (bookingId) => {
        const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
        const bookingDoc = await getDoc(bookingRef);

        if (!bookingDoc.exists) {
            console.error("Booking not found");
            return null;
        }

        return bookingDoc.data();
    };
const printselectedorder = async ()=>{
    console.log("thes orders:")
    console.log(selectedOrders);
}
    const declineSelectedTalents = async () => {
        const selectedOrderIds = Object.keys(selectedOrders).filter(
            (id) => selectedOrders[id]
        );
      

        if (selectedOrderIds.length === 0) {
            console.log("No orders selected.");
            return;
        }

        const userConfirmed = window.confirm("Are you sure you want to release the selected talents?");
        if (userConfirmed) {
            const updates = selectedOrderIds.map(async (bookingId) => {
                const booking = await fetchBooking(bookingId);
                const talent = await fetchTalent(booking.talentId);
                try {
                    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                    await updateDoc(bookingRef, {
                        status: 'canceled',
                        talentId: "",
                    });
                    if (notifyTalent) {
                        createmaildeclined(booking, talent);
                    }
                    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"), {
                        userId: talent.E_Mail,
                        message: `Order Canceled`,
                        read: false,
                        bookingId: bookingId,
                        url: 'talent/orders',
                        createdAt: Timestamp.now()
                    });
                } catch (error) {
                    console.error("Error updating booking: ", error);
                }
            });

            try {
                await Promise.all(updates);
                alert("Talents released successfully.");
                window.location.reload();
            } catch (error) {
                console.error("Error releasing talents: ", error);
            }
        } else {
            console.log("You have cancelled the operation.");
        }
    };

    const fetchTalent = async (talentId) => {
        const talentRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
        const talentDoc = await getDoc(talentRef);

        if (!talentDoc.exists) {
            console.error("Talent not found");
            return null;
        }

        return talentDoc.data();
    };

    const acceptporposedtalent = async (bookingId) => {
        const userConfirmed = window.confirm("Are you sure you want to confirm?");
        if (userConfirmed) {
            const booking = await fetchBooking(bookingId);
            const talent = await fetchTalent(booking.talentId);
            if (bookingId) {
                try {
                    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                    await updateDoc(bookingRef, {
                        status: 'Booked',
                    });
                    alert("Booking updated successfully.");
                   // createTask(booking, talent);
                    createmailcomfirmed(booking, talent);
                    createmailtalentcomfirmed(booking, talent);
                } catch (error) {
                    console.error("Error updating booking: ", error);
                }
            }
        } else {
            console.log("You have cancelled the operation.");
        }
    };

    const declineproposedtalent = async (bookingId) => {
        const userConfirmed = window.confirm("Are you sure you want to release this talent?");
        if (userConfirmed) {
            const booking = await fetchBooking(bookingId);
            const talent = await fetchTalent(booking.talentId);
            if (bookingId) {
                try {
                    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
                    await updateDoc(bookingRef, {
                        status: 'canceled',
                        talentId: "",
                    });
                    alert("Talent released successfully.");
                    createmaildeclined(booking, talent);

                    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"), {
                        userId: talent.E_Mail,
                        message: `Order Canceled`,
                        read: false,
                        bookingId: bookingId,
                        url: 'talent/orders',
                        createdAt: Timestamp.now()
                    });

                    window.location.reload();
                } catch (error) {
                    console.error("Error updating booking: ", error);
                }
            }
        } else {
            console.log("You have cancelled the operation.");
        }
    };

    const createmailcomfirmed = async (filteredBooking, talent, start) => {
        const msg = {
            to: 'booking@set-cast.com, talent@mondula.com,',
            message: {
                subject: 'Planning Orendt has accepted the booking',
                text: ` ${JSON.stringify(filteredBooking)}`,
                html: `
                Dear Team,
                <p>Planning Orendt has accepted the booking and the Order has been confirmed</p>
                <p>Start Date: <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span></p>
                <p>End Date: <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span></p>
                <p>Freelancer: ${talent.name}</p>
                <p>Position: ${filteredBooking.position.label}</p>
                <p>KPI: ${filteredBooking.kpi}</p>
                <p>Check all the booking in <a href="https://app.set-cast.com/">https://app.set-cast.com/</a></p>`,
            },
        };
        await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
    };

    const createmailtalentcomfirmed = async (filteredBooking, talent, start) => {
        const rate = filteredBooking.customRate ?? talent.Tagessatz;
        const msg = {
            to: 'booking@set-cast.com, talent@mondula.com,' + talent.E_Mail,
            message: {
                subject: 'Planning Orendt has accepted the booking',
                text: `${JSON.stringify(filteredBooking)}`,
                html: `
                Dear ${talent.Name},
                <p>We are happy to confirm you for the following production. Please find below the details:</p>
                <p>STATUS: CONFIRMATION</p>
                <p>Talent: ${talent.Name}</p>
                <p>FUNCTION: ${filteredBooking.position.label}</p>
                <p>CLIENT: ${filteredBooking.client.label}</p>
                <p>SET: ${filteredBooking.set}</p>
                <p>DATE/PERIOD: <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span></p>
                <p>DAYRATE: ${rate}</p>
                <p>KPI: ${filteredBooking.kpi}</p>
                <p>CALLTIME: ${filteredBooking.startTime} - ${filteredBooking.endTime}</p>
                <p>LOCATION: ${filteredBooking.location}</p>
                <p>SETCast contact person: ${filteredBooking.proposedBy}</p>
                <p>For questions regarding KPI, shooting schedules, callsheets and briefings, please contact planning@orendtstudios.com.</p>
                <p>In case of illness, please send an email directly to the distribution list planning@orendtstudios.com and krank@set-cast.com by 8:00 a.m. at the latest.</p>
                <p>Invoice details: please upload your invoice in Lano</p>
                <p>We wish you a successful production.</p>
                <p>Warm regards,</p>
                <p>Talents Management Team</p>
                <p>+49 40 39 834 667</p>
                <p>talents@set-cast.com</p>
                <p>LEGEND:</p>
                <p>B28 = Behringstrasse 28a, 22765 Hamburg</p>
                <p>TR4 = Tarpenring 4, 22419 Hamburg</p>
                <p>M= Holy-Allee 3, Metzingen, am Empfang melden</p>
                <p>OSB= Am Rosenkothen 2, 40880 Ratingen</p>`,
            },
        };
        await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
    };

    const createmaildeclined = async (filteredBooking, talent, start) => {
        const msg = {
            to: 'booking@set-cast.com, ' + talent.E_Mail,
            message: {
                subject: 'OPTION RELEASE',
                text: `${JSON.stringify(filteredBooking)}`,
                html: `
                Dear ${talent.Name},
                <p>Unfortunately, we have to release the 1st option we have for you:</p>
                <p>STATUS: RELEASE / CANCELLED</p>
                <p>FUNCTION: ${filteredBooking.position.label}</p>
                <p>CLIENT: ${filteredBooking.client.label}</p>
                <p>DATE/PERIOD: <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span></p>
                <p>PLEASE keep all other 1st options!</p>
                <p>Hoping for your kind understanding. We will keep you in mind and contact you incase there is a spontaneous request.</p>
                <p>Warm regards,</p>
                <p>Talents Management Team</p>
                <p>+49 40 39 834 667</p>
                <p>talents@set-cast.com</p>`,
            },
        };
        await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
    };

    const getUniqueOptions = (field) => {
        const options = Array.from(new Set(events.map(event => event[field])));
        return options.map(option => ({ value: option, label: option }));
    };

    const filteredEvents = events.filter(
        event =>
            (event.boktitle && event.boktitle.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (event.set && event.set.toLowerCase().includes(searchTerm.toLowerCase())) ||
          
            (event.talent && event.talent.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (event.status && event.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (event.company && event.company.toLowerCase().includes(searchTerm.toLowerCase()))
    ).filter(event =>
        (!selectedKPI || event.kpi === selectedKPI.value) &&
        (!selectedGrup || event.grup === selectedGrup.value) &&
        (!selectedClient || event.client === selectedClient.value) &&
        (!selectedSet || event.set === selectedSet.value) &&
        (!selectedLocation || event.location === selectedLocation.value) &&
        (!selectedPosition || event.position === selectedPosition.value) &&
        (!selectedTalent || event.talent === selectedTalent.value)
    );

    return (
        <div>
            <div></div>
            <div style={{ marginTop: '40px', marginBottom: '60px' }}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleChange}
                    className='inputtext search'
                />
            </div>

            <div style={{ marginBottom: '60px', display: 'flex', columnGap: '10px'}}>
                <Select
                    options={getUniqueOptions('kpi')}
                    onChange={setSelectedKPI}
                    placeholder="Select KPI"
                    isClearable
                    styles={customStyles}
                />
      
             
                <Select
                    options={getUniqueOptions('set')}
                    onChange={setSelectedSet}
                    placeholder="Select Set"
                    isClearable
                    styles={customStyles}
                />
         
                <Select
                    options={getUniqueOptions('position')}
                    onChange={setSelectedPosition}
                    placeholder="Select Position"
                    isClearable
                    styles={customStyles}
                />
                <Select
                    options={getUniqueOptions('talent')}
                    onChange={setSelectedTalent}
                    placeholder="Select Talent"
                    isClearable
                    styles={customStyles}
                />
            </div>
            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' , marginBottom: '20px'}}>
                <button className="button-red" onClick={declineSelectedTalents}>
                    Release Selected Talents
                </button>
                <div style={{ flexBasis: "40px", marginLeft: '10px', width: '100%' }} className="custom-checkbox">
                    <input
                        type="checkbox"
                        id="notify-talent-checkbox"
                        checked={notifyTalent}
                        onChange={() => setNotifyTalent(!notifyTalent)}
                    />
                    <label htmlFor="notify-talent-checkbox" style={{ whiteSpace: 'nowrap' }}>
                        Notify Talent on Release/Decline
                    </label>
                </div>
            </div>
           

            <div className='content-wrapper content-wrapper-talent-list'>
                <div className='list-tasks-header'>
                    <div style={{ flexBasis: '200px' }}>start/end</div>
                    <div style={{ flexBasis: '150px' }}>set</div>
                    <div style={{ flexBasis: '50px' }}>kpi</div>
                    <div style={{ flexBasis: '100px' }}>pos.</div>
                    <div style={{ flexBasis: '100px' }}>option</div>
                    <div style={{ flexBasis: '150px' }}>talent</div>
                    <div style={{ flexBasis: '100px' }}>status</div>
                    <div style={{ flexBasis: '250px' }}>client</div>
                    <div style={{ flexBasis: '160px' }}></div>
                </div>
            </div>

            {filteredEvents.map(event => (
                <div className='list-tasks-item' key={event.id}>
                        <div style={{ flexBasis: "40px" }} className="custom-checkbox">
          <input
            type="checkbox"
            id={`checkbox-${event.id}`}
            checked={!!selectedOrders[event.id]}
            onChange={() => handleCheckboxChange(event.id)}
          />
          <label htmlFor={`checkbox-${event.id}`}></label>
        </div>
                    <div style={{ flexBasis: '200px' }}>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</div>
                    <div style={{ flexBasis: '150px' }}>{event.set}</div>
                    <div style={{ flexBasis: '50px' }}>{event.kpi}</div>
                    <div style={{ flexBasis: '100px' }}>{event.position}</div>
                    <div style={{ flexBasis: '100px' }}>{event.option}</div>
                    <div style={{ flexBasis: '150px' }}>{event.talent}</div>
                    <div style={{ flexBasis: '100px' }}>{event.status}</div>
                    <div style={{ flexBasis: '250px' }}>{event.client}</div>
                
                </div>
            ))}

            <div style={{ marginTop: '80px' }}>
                <p><i>Note: Declining will release the talent.</i></p>
            </div>
        </div>
    );
};

export default TalentsOnHoldList;
